import Check from "components/Form/Check";
import { TermContainer, TermSubtitle, Text, SignatureSection, TermUl } from "./styled";

export default function Italian({checked, setChecked}) {
  return (
    <TermContainer>
    {/* <TermTitle>Termo de Uso e Isenção de Responsabilidade</TermTitle> */}
    <Text><strong>Piattaforma per Insegnanti Privati</strong></Text>
    <Text>Questo documento stabilisce i Termini di Utilizzo e l’Esclusione di Responsabilità applicabili all’uso della piattaforma per la registrazione e la promozione di insegnanti privati, di seguito denominata <strong>"Piattaforma"</strong>, da parte di studenti interessati a prenotare lezioni private. Utilizzando la Piattaforma e contattando gli insegnanti registrati, l'utente (studente) dichiara di aver letto, compreso e accettato i termini qui indicati.</Text>

    <TermSubtitle>1. Scopo della Piattaforma</TermSubtitle>
    <Text>La Piattaforma è esclusivamente uno strumento di promozione, funzionando come una galleria di profili di insegnanti privati che forniscono i loro contatti e servizi. La Piattaforma non svolge alcuna mediazione, gestione o controllo delle negoziazioni, degli accordi o dell'esecuzione dei servizi tra studenti e insegnanti.</Text>

    <TermSubtitle>2. Esclusione di Responsabilità</TermSubtitle>
    <TermUl>
        <li>La Piattaforma non è responsabile per la veridicità delle informazioni fornite dagli insegnanti registrati, incluse ma non limitate a qualifiche, esperienze, certificazioni o competenze tecniche.</li>
        <li>Lo studente è l’unico responsabile di verificare l’identità, l’affidabilità e le qualifiche dell’insegnante prima di stipulare qualsiasi accordo o pagamento.</li>
        <li>Qualsiasi negoziazione, accordo, pagamento o rapporto stabilito tra lo studente e l’insegnante avviene a esclusivo rischio delle parti coinvolte, senza generare alcuna responsabilità per la Piattaforma.</li>
        <li>La Piattaforma non è responsabile per danni materiali, morali, finanziari o di altra natura derivanti da controversie, incomprensioni, inadempienze o altri problemi derivanti dal rapporto tra studente e insegnante.</li>
    </TermUl>

    <TermSubtitle>3. Responsabilità dell’Utente (Studente)</TermSubtitle>
    <TermUl>
        <li>Lo studente si impegna a effettuare tutte le verifiche necessarie sull’affidabilità dell’insegnante, come interviste o la richiesta di prove di qualifiche o referenze, prima di formalizzare qualsiasi accordo.</li>
        <li>Lo studente riconosce che l’utilizzo della Piattaforma avviene a proprio rischio, esonerando la Piattaforma da qualsiasi responsabilità per atti, omissioni o comportamenti degli insegnanti registrati.</li>
    </TermUl>

    <TermSubtitle>4. Accettazione dei Termini</TermSubtitle>
    <Text>Utilizzando la Piattaforma, lo studente dichiara espressamente che:</Text>
    <TermUl>
        <li>È consapevole che la Piattaforma funge esclusivamente da spazio promozionale e non è parte di alcuna negoziazione o accordo stabilito tra studente e insegnante;</li>
        <li>Comprende che è sua responsabilità verificare le condizioni dell'accordo e assumersi tutti i rischi connessi;</li>
        <li>Esonera la Piattaforma da qualsiasi responsabilità per eventuali problemi o danni derivanti dal rapporto con l’insegnante.</li>
      </TermUl>
      <SignatureSection>
        <Text><strong>Dichiarazione dell’Utente (Studente)</strong></Text>
        <Check success onChange={() => setChecked(!checked)}
            checked={checked}
            label={
              <Text>Dichiaro di aver letto, compreso e accettato integralmente i termini sopra indicati. Riconosco che la Piattaforma non ha alcuna responsabilità per accordi commerciali o problemi che possono sorgere tra me e l’insegnante, assumendo tutti i rischi e le responsabilità connessi all’ingaggio degli insegnanti registrati.</Text>
            }
        />
      </SignatureSection>
      </TermContainer>
  )
}