import styledCmp from 'styled-components'
import ReactLoading from 'react-loading'

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

export const Load = styledCmp(ReactLoading).attrs(props => ({
    type: 'spin',
    color: props.outline ?
        (
            props.primary ? props.theme.palette.primary.main :
                (props.secondary ? props.theme.palette.secondary.main : props.theme.palette.info.main)
        )
        : props.theme.palette.primary.contrastText,
    height: 20,
    width: 20,
}))`          
    ${
        p => p.spaced ? `
            margin-bottom: 9px;
        ` : ``
    }
`;

export const ColorButton = styled(Button)(({ theme, nospace, colorBlack, noWidth, large, width, between, start, small, className }) => ({
    className: className,
    height: large ? '56px' : small ? '40px' : '48px',
    marginTop: nospace ? '0px' : '12px',
    color: colorBlack ? theme.palette.colors.black : '',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '16px',
    width: width ? width : noWidth ? 'auto' : '100%',

    fontFamily: 'Poppins',
    display: 'flex',
    justifyContent: between ? 'space-between' : start ? 'flex-start' : 'center',
    '& .MuiButton-startIcon': {

    }
}));

export const ButtonIcon = styledCmp.img.attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
}))` 
`;

export const ButtonLabel = styledCmp.div.attrs({
})`           
    width: 100%;
`;