export const static_translation = {
    "bar_chart_general": "General",
    "bar_chart_students": "Your students",
    "dashboard_audio_player_ditado": "Paused",
    "dashboard_audio_player_original": "Original",
    "dashboard_become_teacher": "Activate Teacher Profile",
    "dashboard_create_new_password_check1_text": "Uppercase letter",
    "dashboard_create_new_password_check2_text": "Number",
    "dashboard_create_new_password_check3_text": "At least 6 characters",
    "dashboard_create_new_password_check4_text": "Special character",
    "dashboard_create_new_password_step1_text": "Enter a password",
    "dashboard_create_new_password_step2_text": "Repeat password",
    "dashboard_createpassword_step1_text": "Enter a new password for your account",
    "dashboard_createpassword_step1_title": "New password",
    "dashboard_createpassword_step2_text": "Now you can log in with your new password",
    "dashboard_createpassword_step2_title": "New password successfully created",
    "dashboard_dict_added_increase": "You added more",
    "dashboard_dict_added_words": "words to your vocabulary.",
    "dashboard_dict_added_words_singular": "word to your vocabulary.",
    "dashboard_dict_back_homepage": "Return to the homepage",
    "dashboard_dict_congrats": "Congratulations!",
    "dashboard_dict_obs": "Observations",
    "dashboard_dict_restart": "Start new dictation",
    "dashboard_dict_see_obs": "View observations",
    "dashboard_dict_see_translate": "View translation",
    "dashboard_dict_translate": "Translation",
    "dashboard_dict_writing_handed": "Handwriting",
    "dashboard_dict_writing_meta": "Goal",
    "dashboard_dict_writing_progress": "Your progress",
    "dashboard_dict_writing_special": "Special characters",
    "dashboard_dict_writing_step_1": "1. Download the Mobdict Image Sender app from the App Store or Play Store and log in.",
    "dashboard_dict_writing_step_1_web": "1. Scan the QR Code next to it. You will be redirected to the Mobdict Image Sender website.",
    "dashboard_dict_writing_step_2": "2. Write the dictation you heard on a paper and take a photo.",
    "dashboard_dict_writing_step_2_web": "2. Write down by hand the dictation you heard and take a photo.",
    "dashboard_dict_writing_step_3": "3. Send the image through the app and click the button next to it to finish.",
    "dashboard_dict_writing_step_3_web": "3. Send the image through the website and click the button next to it to finish.",
    "dashboard_dict_writing_step_action": "Already sent",
    "dashboard_dict_writing_step_confirm": "I've checked, Correct",
    "dashboard_dict_writing_title": "Type the text",
    "dashboard_dict_writing_words": "words",
    "dashboard_dict_writing_words_singular": "word",
    "dashboard_dict_wrong_challange": "Challenge",
    "dashboard_dict_wrong_finish": "Finish",
    "dashboard_dict_wrong_omit": "omitted words",
    "dashboard_dict_wrong_omit_singular": "omitted word",
    "dashboard_dict_wrong_reinforcement": "Do reinforcement dictation",
    "dashboard_dict_wrong_words": " words are incorrect",
    "dashboard_dict_wrong_words_singular": "word is incorrect",
    "dashboard_forgot_step1_text": "Enter your registered email to recover your password",
    "dashboard_forgot_step1_title": "Forgot your password?",
    "dashboard_forgot_step1_title_afirm": "Forgot my password",
    "dashboard_forgot_step2_text": "Access the recovery link that was sent to your registered email",
    "dashboard_forgot_step2_title": "Check your email",
    "dashboard_form_account_title": "My account",
    "dashboard_form_back": "Back",
    "dashboard_form_balance_title": "Credit control",
    "dashboard_form_cancel": "Cancel",
    "dashboard_form_confirm_password": "Repeat the password",
    "dashboard_form_delete_checkbox": "Account Deletion",
    "dashboard_form_delete_confirmar": "Confirm",
    "dashboard_form_delete_msg": "After confirmation, your request will be forwarded to our team. The account and data deletion will be completed within 72 hours, definitively and irreversibly. Once the process is finalized, you will receive a confirmation email. By clicking on 'Continue,' you agree to these terms.",
    "dashboard_form_email": "Email",
    "dashboard_form_level": "Level",
    "dashboard_form_name": "Full name",
    "dashboard_form_next": "Continue",
    "dashboard_form_nickname": "Choose your username (Nickname)",
    "dashboard_form_password": "Password",
    "dashboard_form_password_title": "Change password",
    "dashboard_form_password_validation": "New password and confirmation password do not match",
    "dashboard_form_phone": "Cell phone",
    "dashboard_form_save": "Save",
    "dashboard_form_subgender": "Select the literary subgenres",
    "dashboard_form_teacher": "Teacher Code",
    "dashboard_home_cta": "Let's start the dictation",
    "dashboard_home_dicts_days": "days remaining",
    "dashboard_home_dicts_dict": "Dictations",
    "dashboard_home_dicts_done": "completed",
    "dashboard_home_dicts_plan": "Contracted dictations",
    "dashboard_home_dicts_test": "Trial Period",
    "dashboard_home_dicts_test_done": "Trial period ended",
    "dashboard_home_learning": "I am learning",
    "dashboard_home_level": "Level",
    "dashboard_home_ranking_name": "Name",
    "dashboard_home_ranking_points": "Score",
    "dashboard_home_ranking_position": "Rank",
    "dashboard_home_ranking_see_more": "View more",
    "dashboard_home_ranking_title": "Ranking",
    "dashboard_home_see_plans": "View plans",
    "dashboard_home_teacher": "Professor",
    "dashboard_no_plans": "Plans available soon.",
    "dashboard_password_title": "Create your password",
    "dashboard_plan_action": "Subscribe",
    "dashboard_plan_benefits": "See benefits",
    "dashboard_plan_currency": "US$",
    "dashboard_plan_dict": "dictations",
    "dashboard_plan_free_action": "Test",
    "dashboard_plan_name": "Free",
    "dashboard_plan_pop": "Most popular",
    "dashboard_plan_subtitle": "charged annually",
    "dashboard_plan_title": "Subscribe to one of our plans",
    "dashboard_qtdade_TD": "test drive",
    "dashboard_ranking_empty": "No information found",
    "dashboard_register_and": "and",
    "dashboard_register_consent_term_1": "I authorize the sharing of the selected items with other platform users.",
    "dashboard_register_gallery_term_1": "I wish to appear in the language teachers gallery.",
    "dashboard_register_level": "Select language and level",
    "dashboard_register_step0_student": "Student",
    "dashboard_register_step0_teacher": "Teacher",
    "dashboard_register_step0_text": "Specify which category you want to register in",
    "dashboard_register_step0_title": "Define Your Profile",
    "dashboard_register_step1_action": "Continue",
    "dashboard_register_step1_text": "Provide your registration details",
    "dashboard_register_step1_title": "Enter your details",
    "dashboard_register_step2_action": "Close",
    "dashboard_register_step2_back": "Back",
    "dashboard_register_step2_text": "Thank you for registering.",
    "dashboard_register_step2_title": "Registration successfully completed",
    "dashboard_register_subgender": "Select the literary subgenres",
    "dashboard_register_subtitle": "Enter your registration details",
    "dashboard_register_successfull": "Account successfully created",
    "dashboard_register_teacher_code": "Identify your teacher",
    "dashboard_register_teacher_step1_text": "Provide your social networks and a profile picture",
    "dashboard_register_teacher_step1_title": "Social Media",
    "dashboard_register_terms_1": "By selecting and continuing, you agree to our",
    "dashboard_register_terms_2": "Terms of Service",
    "dashboard_register_terms_3": "Privacy policy",
    "dashboard_register_title": "Enter your data",
    "dashboard_register_validation_accept_terms": "Accept the terms",
    "dashboard_register_validation_email": "Enter the email",
    "dashboard_register_validation_not_match": "New password and confirmation do not match",
    "dashboard_register_validation_password": "Enter the new password",
    "dashboard_register_validation_password_confirmation": "Enter the confirmation of the new password",
    "dashboard_send_dict_all": "All",
    "dashboard_send_dict_students_clean": "Clear",
    "dashboard_send_dict_students_subtitle": "Choose which students you want to send this dictation to",
    "dashboard_send_dict_students_title": "Select students",
    "dashboard_send_dict_title": "Write your dictation",
    "dashboard_settings_2fa": "Enable Two-Factor Authentication",
    "dashboard_settings_active": "Active in the gallery",
    "dashboard_settings_adjust_pause": "Adjust dictation pause",
    "dashboard_settings_consent": "Reset permissions",
    "dashboard_settings_consent_title": "Select permissions.",
    "dashboard_settings_default_lang": "Default language",
    "dashboard_settings_define": "Set",
    "dashboard_settings_languages": "Reset teaching languages",
    "dashboard_settings_learn_lang": "Learning language",
    "dashboard_settings_native_lang": "Native language",
    "dashboard_settings_pause": "Pause",
    "dashboard_settings_recurrency": "Recurring subscription",
    "dashboard_settings_subgender": "Reset literary subgenres",
    "dashboard_settings_title": "Settings",
    "dashboard_side_account": "My account",
    "dashboard_side_balance": "Balance sheet",
    "dashboard_side_buy": "Add Dictations",
    "dashboard_side_dict": "Publish Dictation",
    "dashboard_side_exit": "Log out",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_home": "Home",
    "dashboard_side_ranking": "Ranking",
    "dashboard_side_settings": "Settings",
    "dashboard_side_students": "My students",
    "dashboard_side_talk_us": "Contact us",
    "dashboard_side_teachers": "Teacher Gallery",
    "dashboard_side_tutorial": "Tutorials",
    "dashboard_subtitle_subgender": "Minimum 3",
    "dashboard_table_balance_1": "Contracted Dictations",
    "dashboard_table_balance_2": "Generated Dictations Not Performed",
    "dashboard_table_balance_3": "Completed Dictations",
    "dashboard_table_balance_4": "Available Dictations",
    "dashboard_table_balance_desc": "Description",
    "dashboard_table_balance_qtd": "Quantity",
    "dashboard_talkus_about_text": "We are passionate about languages and education. Our goal is to make learning accessible and fun for everyone, using an innovative approach based on dictations. At Mobdict®, transforming your language learning is easier and more fun with dictations! These little pearls of wisdom offer a unique way to absorb culture and language. As you learn a new language, discover dictations that reflect the way native speakers think and live. Each expression brings valuable lessons and cultural context, helping you master nuances and sound more natural.\r\n\r\n",
    "dashboard_talkus_about_title": "About us",
    "dashboard_talkus_email": "Email",
    "dashboard_talkus_support_address": "Address",
    "dashboard_talkus_support_email": "Technical support",
    "dashboard_talkus_support_social": "Social media",
    "dashboard_talkus_title": "Contact us",
    "dashboard_teacher_above_avg": "above the general average",
    "dashboard_teacher_average": "on average",
    "dashboard_teacher_below_avg": "below the general average",
    "dashboard_teacher_dicts_title": "Dictations Completed by Student",
    "dashboard_teacher_show_more": "See more",
    "dashboard_teacher_words_title": "Words Assimilated by Student",
    "dashboard_teacher_wrong_subtitle": "Occurrences",
    "dashboard_teacher_wrong_title": "Most Misspelled Words - Your students",
    "dashboard_teacher_wrong_title_general": "Most Misspelled Words - General",
    "dict_a_newdict": "Start a new dictation",
    "dict_by": "Dictated by: ",
    "dict_correct": "Correct",
    "dict_finish": "Finish",
    "dict_form_dict": "Dictation",
    "dict_form_dict_error": "Insert the text.",
    "dict_form_obs": "Observations",
    "dict_form_ref": "Reference (Enter 'N/A' if none)",
    "dict_form_reference_error": "Insert the reference.",
    "dict_form_select_error": "Select at least one student.",
    "dict_next": "Continue",
    "dict_reference": "Reference:",
    "dict_reinforcement": "Reinforcement dictation",
    "dict_word_bellow": "Use the words below to complete the text",
    "faq_title": "FAQ - Frequently Asked Questions",
    "footer_all_rights": "All rights reserved",
    "footer_support": "Support",
    "footer_talk_us": "Contact us",
    "gallery_consent_modal_title": "Terms of Use and Disclaimer",
    "gallery_info_title": "Contact Information",
    "gallery_languages": "Languages",
    "gallery_score": "Score",
    "galley_student_count": "student in Mobdict",
    "galley_students_count": "students on Mobdict",
    "helpicon_languages": "Mark the languages you teach",
    "helpicon_level": "Proficiency Levels in Language Learning, According to the Common European Framework of Reference for Languages (CEFR)",
    "helpicon_native_lang": "The translations will be in this language.",
    "helpicon_score": "The score represents the average number of words assimilated per student by this teacher",
    "helpicon_subgender": "The sayings will be created based on these choices, making them more interesting and aligned with your preferences.",
    "helpicon_teacher": "If you already have a teacher, enter the code provided or request it directly from them.",
    "helpicon_username": "This information will be visible to other Mobdict users.",
    "Infantojuvenil": "Child and youth",
    "landpage_banner_balloon": "Improve a new language!",
    "landpage_download_button": "Download available on",
    "landpage_flags_title": "Improve a language with Mobdict®",
    "landpage_free_action": "I want to try",
    "landpage_free_text": "You can practice the language you are studying, at your level, by testing Mobdict® for free for one week and do up to 5 dictations to see your vocabulary increase with each challenge.",
    "landpage_free_title": "Take a free test",
    "landpage_how_works_action": "I want to know the plans",
    "landpage_how_works_text": "We are passionate about languages and education. Our goal is to make learning accessible and fun for everyone, using an innovative approach based on dictations.",
    "landpage_how_works_title": "Our greatest satisfaction is helping in the learning of a new language",
    "landpage_learn_action": "Start now",
    "landpage_learn_text": "Our teaching method focuses on practicing languages through dictations, a technique that combines listening comprehension, reading, and writing practice to naturally and effectively improve your language skills.",
    "landpage_learn_title": "Learn much more 😎",
    "landpage_more_text": "Watch our video to understand how language learning using dictations can benefit you.",
    "landpage_more_title": "Learn in the best way 🎬",
    "landpage_write_text": "Writing by hand can improve retention and vocabulary comprehension as it involves deeper cognitive processes. Send your manuscripts to Mobdict® using the Mobdict Image Sender app.",
    "landpage_write_title": "Write by Hand and Learn more with Mobdict®",
    "lg-acc": "Sign up",
    "lg-acc-txt": "Don't have an account yet? <a id=\"lg-acc\" href=\"/apl/register\">Sign up</a>",
    "lg-auth-text": "With the <strong>Google Authenticator</strong> app, or another authentication app, scan the QRCode below to generate your token",
    "lg-email": "Email",
    "lg-error-pc": "Fill in all fields",
    "lg-error-pc-cloudflare": "Wait for verification or check the Cloudflare checkbox.",
    "lg-error-pc-token": "Fill in the field",
    "lg-fgp": "Forgot your password?",
    "lg-fgpx": "Forgot your password?",
    "lg-google-auth": "Enter the code available in the authentication app",
    "lg-idioma": "Language",
    "lg-legend-login": "Login",
    "lg-legend-token": "Enter your token",
    "lg-senha": "Password",
    "lg-token": "Token",
    "login": "Login",
    "login_mfa": "Use the authentication app of your choice and scan the QR code below to generate your token",
    "login_mfa_qrcode": "Generate new QR Code",
    "login-2": "Authenticating...",
    "login-alt": "Enter with",
    "login-signup": "Don't have an account?",
    "notifications_modal_all_read": "Mark all as read",
    "notifications_modal_any": "You have no notifications.",
    "notifications_modal_button_1": "All",
    "notifications_modal_button_2": "Unread",
    "notifications_modal_message": "New dictation available!",
    "notifications_modal_time_more": "** days ago",
    "notifications_modal_time_one": "1 day ago",
    "notifications_modal_time_today": "Today",
    "notifications_modal_title": "Notifications",
    "payment_promo_code": "Promotional Code",
    "payments_copy_paste": "Pix Copia e Cola",
    "payments_copy_paste_tooltip": "Copiado",
    "pix_error": "Erro ao gerar Qr Code Pix",
    "pix_success": "Cobrança Pix criada com sucesso",
    "plan_teacher_benefits_1": "Practical experience – Complete up to 5 free dictations to explore the tool from your students' perspective.",
    "plan_teacher_benefits_2": "Progress tracking – After validating your profile as a teacher, track the development of your subscribed students in real time.",
    "plan_teacher_benefits_3": "Visibility and opportunities – Promote your services and social networks directly on the platform, connecting with students looking for courses or private lessons.",
    "plan_teacher_benefits_cta": "Join Mobdict and expand your possibilities as an educator!",
    "plan_teacher_benefits_text": "By registering on the platform, you will have access to exclusive features to optimize your experience and enhance your students' learning.",
    "plan_teacher_benefits_title": "Benefits for Teachers on Mobdict",
    "plans_form_payments": "Payment Method",
    "plans_payment_card": "Credit Card",
    "register_validation_language": "Select at least one language.",
    "register_validation_subgender": "Select at least 3 subgenres to continue",
    "search_label_teachers": "Search for a teacher",
    "search_placeholder": "Search...",
    "settings_saved": "Changes made. Click 'Continue' to save them.",
    "settings_teacher_warning": "The selected teacher does not teach this language. Please enter another code (leave blank if you wish to proceed without a teacher).",
    "settings_teacher_warning_error": "Teacher or language not changed.",
    "settings_teacher_warning_title": "Change your teacher",
    "students_dict": "Dictations Completed",
    "students_known": "Words Assimilated:",
    "students_label_green": "Assimilated Word",
    "students_label_red": "Non-assimilated Word",
    "students_languages": "Learning Languages:",
    "students_title": "My Students",
    "students_wrong": "Wrongest words:",
    "teacher_cv_title": "Provide a brief résumé",
    "teacher_form_city": "City",
    "teacher_form_country": "Country",
    "teacher_form_cv": "Résumé",
    "teacher_form_facebook": "Facebook",
    "teacher_form_instagram": "Instagram",
    "teacher_form_language": "Languages taught",
    "teacher_form_linkedin": "LinkedIn",
    "teacher_form_photo": "Photo",
    "teacher_form_social": "Social media",
    "teacher_form_state": "Federative unit",
    "teacher_location_title": "Nationality",
    "teacher_pix_checkout_error": "You cannot subscribe to a plan as a Teacher.",
    "teacher_register_consent_error": "Select at least one form of contact (email or phone)",
    "teacher_register_consent_location": "Nationality",
    "teacher_register_consent_term_error": "Accept the term",
    "teacher_register_info_1": "Your profile information will be visible to all users. Choose which ones you want to share:",
    "teacher_register_info_2": "ATTENTION: After activating your profile as a Teacher, you will no longer be able to perform dictations on the platform, but you will be able to track your students' performance.",
    "teacher_register_language_error": "Enter at least one language",
    "teacher_register_languages": "Languages",
    "teacher_register_languages_subtitle": "Mark the languages you teach",
    "teacher_register_photo_error": "Failed to save image",
    "teacher_register_photo_type_error": "Invalid format. Use png, jpeg, or jpg",
    "teacher_students_subtitle": "Caption",
    "teachers_title": "Teachers' Gallery",
    "title": "Welcome",
    "val-token": "Validate",
    "val-token-2": "Validating...",
    "x26": "By selecting and continuing, you agree to our Terms of Service, Privacy Policy, and LGPD",
    "x9": "By selecting and continuing, you agree to our Terms of Service and Privacy Policy"
}