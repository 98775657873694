import styled from "styled-components";

export const Subtitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 41px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
`;

export const Overlay = styled.div.attrs({
})`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.palette.colors.overlay};
    z-index: 1001;
    cursor: auto;
`;

export const ModalContainer = styled.div.attrs({
})`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
`;


export const ModalContent = styled.div.attrs({
})`

    background: ${props => props.theme.palette.colors.white};
    border-radius: 8px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 520px;
    width: 100%;
    overflow-y: auto; 
    max-height: 90vh;

    box-sizing: border-box;
    animation: slideDown 0.3s ease-out;

    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideUp {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    @media (max-width: 1000px) {
        padding: 20px;
        max-width: 100%;
        min-width: 100%;
    }

    @media (max-width: 480px) {
        padding: 15px;
        gap: 15px;
    }
`;

export const ModalLabel = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
`;

export const ModalTitleContainer = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding: 16px 30px 0px 25px;
    position: relative;
`;

export const ModalBody = styled.div.attrs({
})`
    display: flex;
    gap: 20px;
    padding: 0 50px 50px;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    @media (max-width: 798px) {
        justify-content: center;
    }
`;

export const ButtonClose = styled.div.attrs({
})`
    position: absolute;
    right: 40px;
    @media (max-width: 798px) {
        right: 5px;
        top: 20px;
    }
`;

export const ButtonContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 100%;
    padding: 16px 0;
    box-sizing: border-box;
    border-top: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
    @media (max-width: 798px) {
        padding: 20px 20px;
    }
`;

export const PaymentsContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media (max-width: 798px) {
    flex-direction: column;
  }
`;

export const PaymentItem = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 200px;
  // height: 350px;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
  cursor: pointer;
`;

export const SafeImage = styled.img.attrs({
  onError: e => e.target.style.display = 'none',
  onLoad: e => e.target.style.display = 'initial'
})``

export const QRCodeImage = styled(SafeImage).attrs((props) => ({
  src: props.src,
  alt: props.alt ? props.alt : props.icon,
}))`
  width: 200px;
  height: 200px;
  margin: 0 auto;
  display: block;
`

export const CopyPasteContainer = styled.div.attrs({
})`
  display: flex;
  gap: 10px;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  border-radius: 8px;
  padding: 10px;
`

export const CodeContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  width: 100%;
`

