import styled from "styled-components";


export const CardContainer = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    max-width: 187.25px;
`;

export const CardHeader = styled.div.attrs({
})`
    width: 187.25px;
    height: 56px;
    background: ${props => props.theme.palette.plans[props.plan]};
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 8px 8px 0 0;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 22.4px;
    text-align: left;
    color: ${props => props.theme.palette.colors.black};
`;

export const PlansBody = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    margin: 8px 0;
`;

export const CardBody = styled.div.attrs({
})`
    display: flex;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.palette.colors.backgroundgrey};
    border-bottom: 1px solid ${props => props.theme.palette.colors.lightgrey};
    margin-bottom: 2px;
`;

export const CardLabel = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: ${props => props.theme.palette.primary.grey};
    text-decoration: ${props => props.hover ? 'underline' : 'none'};
    :hover {
        font-weight: ${props => props.hover ? '700' : '500'};
    }
`;

export const FamousCard = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 24px;
    margin-bottom: 10px;
`;


export const FamousTitle = styled.div.attrs({
})`
    font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    text-align: center;

    display: flex;
    align-items: center;
    gap: 6px;
    color: ${props => props.theme.palette.colors.black};
`;