export const static_translation = {
    "bar_chart_general": "Generale",
    "bar_chart_students": "I tuoi studenti",
    "dashboard_audio_player_ditado": "Pausato",
    "dashboard_audio_player_original": "Originale",
    "dashboard_become_teacher": "Attivare Profilo Professore",
    "dashboard_create_new_password_check1_text": "Lettera maiuscola",
    "dashboard_create_new_password_check2_text": "Numero",
    "dashboard_create_new_password_check3_text": "Almeno 6 caratteri",
    "dashboard_create_new_password_check4_text": "Carattere speciale",
    "dashboard_create_new_password_step1_text": "Inserisci una password",
    "dashboard_create_new_password_step2_text": "Ripeti la password",
    "dashboard_createpassword_step1_text": "Inserisci una nuova password per il tuo account",
    "dashboard_createpassword_step1_title": "Nuova password",
    "dashboard_createpassword_step2_text": "Ora puoi accedere con la tua nuova password",
    "dashboard_createpassword_step2_title": "Nuova password creata con successo",
    "dashboard_dict_added_increase": "Hai aggiunto di più",
    "dashboard_dict_added_words": "parole al tuo vocabolario.",
    "dashboard_dict_added_words_singular": "parola al tuo vocabolario.",
    "dashboard_dict_back_homepage": "Torna alla pagina principale",
    "dashboard_dict_congrats": "Congratulazioni!",
    "dashboard_dict_obs": "Osservazioni",
    "dashboard_dict_restart": "Inizia nuova dettatura",
    "dashboard_dict_see_obs": "Visualizza osservazioni",
    "dashboard_dict_see_translate": "Vedi traduzione",
    "dashboard_dict_translate": "Traduzione",
    "dashboard_dict_writing_handed": "Scrittura a mano",
    "dashboard_dict_writing_meta": "Obiettivo",
    "dashboard_dict_writing_progress": "I tuoi progressi",
    "dashboard_dict_writing_special": "Caratteri speciali",
    "dashboard_dict_writing_step_1": "1. Scarica l'app Mobdict Image Sender dall'App Store o Play Store e accedi.",
    "dashboard_dict_writing_step_1_web": "1. Scansiona il codice QR accanto. Verrai reindirizzato al sito Mobdict Image Sender.",
    "dashboard_dict_writing_step_2": "2. Scrivi il dettato che hai sentito su un foglio e scatta una foto.",
    "dashboard_dict_writing_step_2_web": "2. Scrivi a mano il dettato che hai ascoltato e scatta una foto.",
    "dashboard_dict_writing_step_3": "3. Invia l'immagine tramite l'app e fai clic sul pulsante accanto per terminare.",
    "dashboard_dict_writing_step_3_web": "3. Invia l'immagine tramite il sito e fai clic sul pulsante accanto per completare.",
    "dashboard_dict_writing_step_action": "Già inviato",
    "dashboard_dict_writing_step_confirm": "Ho verificato, Correggi",
    "dashboard_dict_writing_title": "Digita il testo",
    "dashboard_dict_writing_words": "parole",
    "dashboard_dict_writing_words_singular": "parola",
    "dashboard_dict_wrong_challange": "Sfida",
    "dashboard_dict_wrong_finish": "Finire",
    "dashboard_dict_wrong_omit": "parole omesse",
    "dashboard_dict_wrong_omit_singular": "parola omessa",
    "dashboard_dict_wrong_reinforcement": "Fai il dettato di rinforzo",
    "dashboard_dict_wrong_words": " parole sono sbagliate",
    "dashboard_dict_wrong_words_singular": "parola è errata",
    "dashboard_forgot_step1_text": "Inserisci la tua email registrata per recuperare la tua password",
    "dashboard_forgot_step1_title": "Hai dimenticato la password?",
    "dashboard_forgot_step1_title_afirm": "Ho dimenticato la mia password",
    "dashboard_forgot_step2_text": "Accedi al link di recupero che è stato inviato al tuo email registrato",
    "dashboard_forgot_step2_title": "Controlla la tua email",
    "dashboard_form_account_title": "Il mio account",
    "dashboard_form_back": "Indietro",
    "dashboard_form_balance_title": "Controllo dei crediti",
    "dashboard_form_cancel": "Annulla",
    "dashboard_form_confirm_password": "Ripeti la password",
    "dashboard_form_delete_checkbox": "Eliminazione account",
    "dashboard_form_delete_confirmar": "Confermare",
    "dashboard_form_delete_msg": "Dopo la conferma, la tua richiesta sarà inoltrata al nostro team. L'eliminazione dell'account e dei tuoi dati sarà completata entro 72 ore in modo definitivo e irreversibile. Una volta completato il processo, riceverai un'email di conferma. Cliccando su 'Continua', accetti questi termini.",
    "dashboard_form_email": "Email",
    "dashboard_form_level": "Livello",
    "dashboard_form_name": "Nome completo",
    "dashboard_form_next": "Continua",
    "dashboard_form_nickname": "Scegli il tuo username (Soprannome)",
    "dashboard_form_password": "Password",
    "dashboard_form_password_title": "Cambia password",
    "dashboard_form_password_validation": "La nuova password e la conferma della nuova password non coincidono",
    "dashboard_form_phone": "Cellulare",
    "dashboard_form_save": "Salva",
    "dashboard_form_subgender": "Seleziona i sottogeneri letterari",
    "dashboard_form_teacher": "Codice Professore",
    "dashboard_home_cta": "Iniziamo il dettato",
    "dashboard_home_dicts_days": "giorni restanti",
    "dashboard_home_dicts_dict": "Dettati",
    "dashboard_home_dicts_done": "completati",
    "dashboard_home_dicts_plan": "Dettati contrattati",
    "dashboard_home_dicts_test": "Periodo Di Prova",
    "dashboard_home_dicts_test_done": "Periodo di prova concluso",
    "dashboard_home_learning": "Sto imparando",
    "dashboard_home_level": "Livello",
    "dashboard_home_ranking_name": "Nome",
    "dashboard_home_ranking_points": "Punteggio",
    "dashboard_home_ranking_position": "Posizione",
    "dashboard_home_ranking_see_more": "Vedi di più",
    "dashboard_home_ranking_title": "Classifica",
    "dashboard_home_see_plans": "Vedi piani",
    "dashboard_home_teacher": "Professore",
    "dashboard_no_plans": "Piani disponibili a breve.",
    "dashboard_password_title": "Crea la tua password",
    "dashboard_plan_action": "Abbonati",
    "dashboard_plan_benefits": "Vedi i benefici",
    "dashboard_plan_currency": "US$",
    "dashboard_plan_dict": "dettati",
    "dashboard_plan_free_action": "Testare",
    "dashboard_plan_name": "Gratis",
    "dashboard_plan_pop": "Più popolare",
    "dashboard_plan_subtitle": "addebitati annualmente",
    "dashboard_plan_title": "Abbonati a uno dei nostri piani",
    "dashboard_qtdade_TD": "prova gratuita",
    "dashboard_ranking_empty": "Nessuna informazione trovata",
    "dashboard_register_and": "e",
    "dashboard_register_consent_term_1": "Autorizzo la condivisione degli elementi selezionati con altri utenti della piattaforma.",
    "dashboard_register_gallery_term_1": "Desidero apparire nella galleria degli insegnanti di lingue.",
    "dashboard_register_level": "Seleziona la lingua e il livello",
    "dashboard_register_step0_student": "Studente",
    "dashboard_register_step0_teacher": "Professore",
    "dashboard_register_step0_text": "Specifica in quale categoria desideri registrarti",
    "dashboard_register_step0_title": "Definisci Il Tuo Profilo",
    "dashboard_register_step1_action": "Continua",
    "dashboard_register_step1_text": "Fornisci i tuoi dati di registrazione",
    "dashboard_register_step1_title": "Inserisci i tuoi dati",
    "dashboard_register_step2_action": "Chiudere",
    "dashboard_register_step2_back": "Torna indietro",
    "dashboard_register_step2_text": "Grazie per esserti registrato.",
    "dashboard_register_step2_title": "Registrazione completata con successo",
    "dashboard_register_subgender": "Seleziona i sottogeneri letterari",
    "dashboard_register_subtitle": "Inserisci i tuoi dati di registrazione",
    "dashboard_register_successfull": "Account creato con successo",
    "dashboard_register_teacher_code": "Identifica il tuo insegnante",
    "dashboard_register_teacher_step1_text": "Fornisci i tuoi social network e una foto profilo",
    "dashboard_register_teacher_step1_title": "Social Media",
    "dashboard_register_terms_1": "Selezionando e continuando, accetti i nostri",
    "dashboard_register_terms_2": "Termini di servizio",
    "dashboard_register_terms_3": "Politica sulla privacy",
    "dashboard_register_title": "Inserisci i tuoi dati",
    "dashboard_register_validation_accept_terms": "Accetta i termini",
    "dashboard_register_validation_email": "Inserisci l'email",
    "dashboard_register_validation_not_match": "La nuova password e la conferma non corrispondono",
    "dashboard_register_validation_password": "Inserisci la nuova password",
    "dashboard_register_validation_password_confirmation": "Inserisci la conferma della nuova password",
    "dashboard_send_dict_all": "Tutti",
    "dashboard_send_dict_students_clean": "Cancella",
    "dashboard_send_dict_students_subtitle": "Scegli a quali studenti inviare questo dettato",
    "dashboard_send_dict_students_title": "Seleziona studenti",
    "dashboard_send_dict_title": "Scrivi il tuo dettato",
    "dashboard_settings_2fa": "Abilita l'autenticazione a due fattori",
    "dashboard_settings_active": "Attivo nella galleria",
    "dashboard_settings_adjust_pause": "Regola la pausa nel dettato",
    "dashboard_settings_consent": "Reimposta permessi",
    "dashboard_settings_consent_title": "Seleziona le autorizzazioni.",
    "dashboard_settings_default_lang": "Lingua predefinita",
    "dashboard_settings_define": "Imposta",
    "dashboard_settings_languages": "Reimposta le lingue di insegnamento",
    "dashboard_settings_learn_lang": "Lingua di apprendimento",
    "dashboard_settings_native_lang": "Lingua madre",
    "dashboard_settings_pause": "Pausa",
    "dashboard_settings_recurrency": "Abbonamento ricorrente",
    "dashboard_settings_subgender": "Reimposta i sottogeneri letterari",
    "dashboard_settings_title": "Impostazioni",
    "dashboard_side_account": "Il mio account",
    "dashboard_side_balance": "Bilancio",
    "dashboard_side_buy": "Aggiungi Dettati",
    "dashboard_side_dict": "Pubblica Dettato",
    "dashboard_side_exit": "Esci",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_home": "Home",
    "dashboard_side_ranking": "Classifica",
    "dashboard_side_settings": "Impostazioni",
    "dashboard_side_students": "I miei studenti",
    "dashboard_side_talk_us": "Contattaci",
    "dashboard_side_teachers": "Galleria dei Professori",
    "dashboard_side_tutorial": "Tutorial",
    "dashboard_subtitle_subgender": "Minimo 3",
    "dashboard_table_balance_1": "Dettati Contrattati",
    "dashboard_table_balance_2": "Dettati Generati E Non Eseguiti",
    "dashboard_table_balance_3": "Dettati Realizzati",
    "dashboard_table_balance_4": "Dettati Disponibili",
    "dashboard_table_balance_desc": "Descrizione",
    "dashboard_table_balance_qtd": "Quantità",
    "dashboard_talkus_about_text": "Siamo appassionati di lingue ed educazione. Il nostro obiettivo è rendere l'apprendimento accessibile e divertente per tutti, utilizzando un approccio innovativo basato sui dettati. Nel Mobdict®, trasformare il tuo apprendimento delle lingue è più facile e divertente con i dettati! Queste piccole perle di saggezza offrono un modo unico di assorbire la cultura e la lingua. Mentre impari una nuova lingua, scopri proverbi che riflettono il modo di pensare e vivere dei madrelingua. Ogni espressione porta con sé una lezione preziosa e un contesto culturale, aiutandoti a padroneggiare le sfumature e a suonare più naturale.",
    "dashboard_talkus_about_title": "Chi siamo",
    "dashboard_talkus_email": "Email",
    "dashboard_talkus_support_address": "Indirizzo",
    "dashboard_talkus_support_email": "Supporto tecnico",
    "dashboard_talkus_support_social": "Social media",
    "dashboard_talkus_title": "Contattaci",
    "dashboard_teacher_above_avg": "sopra la media generale",
    "dashboard_teacher_average": "in media",
    "dashboard_teacher_below_avg": "sotto la media generale",
    "dashboard_teacher_dicts_title": "Dettati Realizzati per Studente",
    "dashboard_teacher_show_more": "Vedi di più",
    "dashboard_teacher_words_title": "Parole Assimilate per Studente",
    "dashboard_teacher_wrong_subtitle": "Occorrenze",
    "dashboard_teacher_wrong_title": "Parole più Sbagliate - I tuoi studenti",
    "dashboard_teacher_wrong_title_general": "Parole più Sbagliate - Generale",
    "dict_a_newdict": "Inizia un nuovo dettato",
    "dict_by": "Dettato da: ",
    "dict_correct": "Correggi",
    "dict_finish": "Finire",
    "dict_form_dict": "Dettato",
    "dict_form_dict_error": "Inserisci il testo.",
    "dict_form_obs": "Osservazioni",
    "dict_form_ref": "Riferimento (inserisci 'N/A' se non disponibile)",
    "dict_form_reference_error": "Inserisci il riferimento.",
    "dict_form_select_error": "Seleziona almeno uno studente.",
    "dict_next": "Continua",
    "dict_reference": "Riferimento:",
    "dict_reinforcement": "Dettato di rinforzo",
    "dict_word_bellow": "Usa le parole qui sotto per completare il testo",
    "faq_title": "FAQ - Domande frequenti",
    "footer_all_rights": "Tutti i diritti riservati",
    "footer_support": "Supporto",
    "footer_talk_us": "Contattaci",
    "gallery_consent_modal_title": "Termini di Utilizzo e Clausola di Esclusione di Responsabilità",
    "gallery_info_title": "Informazioni di Contatto",
    "gallery_languages": "Lingue",
    "gallery_score": "Punteggio",
    "galley_student_count": "studente in Mobdict",
    "galley_students_count": "studenti su Mobdict",
    "helpicon_languages": "Segna le lingue che insegni",
    "helpicon_level": "Livelli di competenza nell'apprendimento delle lingue, secondo il Quadro Comune Europeo di Riferimento per le Lingue (QCER)",
    "helpicon_native_lang": "Le traduzioni saranno in questa lingua.",
    "helpicon_score": "Il punteggio rappresenta la media di parole assimilate per studente da questo insegnante",
    "helpicon_subgender": "I detti saranno creati in base a queste scelte, rendendoli più interessanti e in linea con le tue preferenze.",
    "helpicon_teacher": "Se hai già un insegnante, inserisci il codice fornito o richiedilo direttamente a lui.",
    "helpicon_username": "Queste informazioni saranno visibili ad altri utenti di Mobdict.",
    "Infantojuvenil": "Infantile e giovanile",
    "landpage_banner_balloon": "Migliora una nuova lingua!",
    "landpage_download_button": "Download disponibile su",
    "landpage_flags_title": "Migliora una lingua con Mobdict®",
    "landpage_free_action": "Voglio provare",
    "landpage_free_text": "Puoi praticare la lingua che stai studiando, al livello che hai raggiunto, testando Mobdict® gratuitamente per una settimana e fare fino a 5 dettati per vedere il tuo vocabolario aumentare con ogni sfida.",
    "landpage_free_title": "Fai una prova gratuita",
    "landpage_how_works_action": "Voglio conoscere i piani",
    "landpage_how_works_text": "Siamo appassionati di lingue ed educazione. Il nostro obiettivo è rendere l'apprendimento accessibile e divertente per tutti, utilizzando un approccio innovativo basato sui dettati.",
    "landpage_how_works_title": "La nostra più grande soddisfazione è aiutare nell'apprendimento di una nuova lingua",
    "landpage_learn_action": "Inizia ora",
    "landpage_learn_text": "Il nostro metodo di insegnamento si concentra sulla pratica delle lingue attraverso i dettati, una tecnica che combina comprensione orale, lettura e pratica scritta per migliorare le tue competenze linguistiche in modo naturale ed efficace.",
    "landpage_learn_title": "Impara molto di più 😎",
    "landpage_more_text": "Guarda il nostro video per capire come l'apprendimento delle lingue utilizzando i dettati può avvantaggiarti.",
    "landpage_more_title": "Impara nel miglior modo possibile 🎬",
    "landpage_write_text": "Scrivere a mano può migliorare la ritenzione e la comprensione del vocabolario, poiché coinvolge processi cognitivi più profondi. Invia i tuoi manoscritti a Mobdict® utilizzando l'app Mobdict Image Sender.",
    "landpage_write_title": "Scrivi a mano e impara di più con Mobdict®",
    "lg-acc": "Registrati",
    "lg-acc-txt": "Non hai ancora un account? <a id=\"lg-acc\" href=\"/apl/register\">Registrati</a>",
    "lg-auth-text": "Con l'app <strong>Google Authenticator</strong>, o un'altra app di autenticazione, scansiona il QRCode qui sotto per generare il tuo token",
    "lg-email": "Email",
    "lg-error-pc": "Compila tutti i campi",
    "lg-error-pc-cloudflare": "Attendere la verifica o selezionare la casella di controllo di Cloudflare.",
    "lg-error-pc-token": "Compila il campo",
    "lg-fgp": "Hai dimenticato la tua password?",
    "lg-fgpx": "Hai dimenticato la tua password?",
    "lg-google-auth": "Inserisci il codice disponibile nell'app di autenticazione",
    "lg-idioma": "Lingua",
    "lg-legend-login": "Accesso",
    "lg-legend-token": "Inserisci il tuo token",
    "lg-senha": "Password",
    "lg-token": "Token",
    "login": "Accedi",
    "login_mfa": "Utilizza l'app di autenticazione di tua scelta e scansiona il codice QR qui sotto per generare il tuo token",
    "login_mfa_qrcode": "Genera nuovo QR Code",
    "login-2": "Autenticazione in corso...",
    "login-alt": "Entrare con",
    "login-signup": "Non hai un account?",
    "notifications_modal_all_read": "Segna tutto come letto",
    "notifications_modal_any": "Non hai notifiche.",
    "notifications_modal_button_1": "Tutte",
    "notifications_modal_button_2": "Non lette",
    "notifications_modal_message": "Nuovo dettato disponibile!",
    "notifications_modal_time_more": "** giorni fa",
    "notifications_modal_time_one": "1 giorno fa",
    "notifications_modal_time_today": "Oggi",
    "notifications_modal_title": "Notifiche",
    "payment_promo_code": "Codice promozionale",
    "payments_copy_paste": "Pix Copia e Cola",
    "payments_copy_paste_tooltip": "Copiado",
    "pix_error": "Erro ao gerar Qr Code Pix",
    "pix_success": "Cobrança Pix criada com sucesso",
    "plan_teacher_benefits_1": "Esperienza pratica – Esegui fino a 5 dettati gratuiti per esplorare lo strumento dal punto di vista dei tuoi studenti.",
    "plan_teacher_benefits_2": "Monitoraggio dei progressi – Dopo la convalida del tuo profilo come insegnante, segui lo sviluppo dei tuoi studenti abbonati in tempo reale.",
    "plan_teacher_benefits_3": "Visibilità e opportunità – Promuovi i tuoi servizi e social network direttamente sulla piattaforma, connettendoti con studenti in cerca di corsi o lezioni private.",
    "plan_teacher_benefits_cta": "Unisciti a Mobdict e amplia le tue possibilità come educatore!",
    "plan_teacher_benefits_text": "Registrandoti sulla piattaforma, avrai accesso a funzionalità esclusive per ottimizzare la tua esperienza e potenziare l'apprendimento dei tuoi studenti.",
    "plan_teacher_benefits_title": "Benefici per insegnanti su Mobdict",
    "plans_form_payments": "Metodo di pagamento",
    "plans_payment_card": "Carta di credito",
    "register_validation_language": "Seleziona almeno una lingua.",
    "register_validation_subgender": "Seleziona almeno 3 sottogeneri per continuare",
    "search_label_teachers": "Cerca un insegnante",
    "search_placeholder": "Cerca...",
    "settings_saved": "Modifiche apportate. Clicca su 'Continua' per salvarle.",
    "settings_teacher_warning": "L'insegnante selezionato non insegna questa lingua. Inserisci un altro codice (lascia vuoto se desideri procedere senza un insegnante).",
    "settings_teacher_warning_error": "Insegnante o lingua non modificati.",
    "settings_teacher_warning_title": "Cambia il tuo insegnante",
    "students_dict": "Dettati completati",
    "students_known": "Parole assimilate:",
    "students_label_green": "Parola assimilata",
    "students_label_red": "Parola non assimilata",
    "students_languages": "Lingue di apprendimento:",
    "students_title": "I miei studenti",
    "students_wrong": "Parole più sbagliate:",
    "teacher_cv_title": "Fornire un breve curriculum",
    "teacher_form_city": "Città",
    "teacher_form_country": "Paese",
    "teacher_form_cv": "Curriculum",
    "teacher_form_facebook": "Facebook",
    "teacher_form_instagram": "Instagram",
    "teacher_form_language": "Lingue insegnate",
    "teacher_form_linkedin": "LinkedIn",
    "teacher_form_photo": "Foto",
    "teacher_form_social": "Social media",
    "teacher_form_state": "Unità federativa",
    "teacher_location_title": "Nazionalità",
    "teacher_pix_checkout_error": "Non puoi sottoscrivere un piano come Insegnante.",
    "teacher_register_consent_error": "Seleziona almeno un metodo di contatto (email o telefono)",
    "teacher_register_consent_location": "Nazionalità",
    "teacher_register_consent_term_error": "Accetta il termine",
    "teacher_register_info_1": "Le informazioni del tuo profilo saranno visibili a tutti gli utenti. Scegli quali desideri condividere:",
    "teacher_register_info_2": "ATTENZIONE: Dopo aver attivato il tuo profilo come Insegnante, non potrai più eseguire dettati sulla piattaforma, ma potrai monitorare le prestazioni dei tuoi studenti.",
    "teacher_register_language_error": "Inserisci almeno una lingua",
    "teacher_register_languages": "Lingue",
    "teacher_register_languages_subtitle": "Segna le lingue che insegni",
    "teacher_register_photo_error": "Errore nel salvare l'immagine",
    "teacher_register_photo_type_error": "Formato non valido. Usa png, jpeg o jpg",
    "teacher_students_subtitle": "Didascalia",
    "teachers_title": "Galleria dei Docenti",
    "title": "Benvenuti",
    "val-token": "Convalidare",
    "val-token-2": "Convalidando...",
    "x26": "Selezionando e continuando, accetti i nostri Termini di servizio, Informativa sulla privacy e LGPD",
    "x9": "Selezionando e continuando, accetti i nostri Termini di servizio e la Politica sulla privacy"
}