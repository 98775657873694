import styled from "styled-components";

export const Subtitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 41px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
`;

export const Overlay = styled.div.attrs({
})`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.theme.palette.colors.overlay};
    z-index: 1001;
    cursor: auto;
`;

export const ModalContainer = styled.div.attrs({
})`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10000991;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
`;


export const ModalContent = styled.div.attrs({
})`

    background: ${props => props.theme.palette.colors.white};
    border-radius: 8px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 676px;
    width: 100%;
    overflow-y: auto; 
    max-height: 90vh;

    box-sizing: border-box;
    animation: slideDown 0.3s ease-out;

    @keyframes slideDown {
        from {
            opacity: 0;
            transform: translateY(-100%);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }

    @keyframes slideUp {
        from {
            opacity: 1;
            transform: translateY(0);
        }
        to {
            opacity: 0;
            transform: translateY(-100%);
        }
    }

    @media (max-width: 1000px) {
        padding: 20px;
        max-width: 100%;
        min-width: 100%;
    }

    @media (max-width: 480px) {
        padding: 15px;
        gap: 15px;
    }
`;

export const ModalLabel = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 48px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
`;

export const ModalTitleContainer = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding: 32px 30px 0px 25px;
    position: relative;
`;

export const ModalBody = styled.div.attrs({
})`
    display: flex;
    padding: 0px 40px 20px 40px;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box;
    @media (max-width: 798px) {
        justify-content: center;
    }
`;

export const ButtonClose = styled.div.attrs({
})`
    position: absolute;
    right: 40px;
    @media (max-width: 798px) {
        right: 5px;
        top: 20px;
    }
`;

export const Text = styled.div.attrs({
})`
    font-size: ${props => props.small ? '14px' : '16px'};
    font-weight: ${props => props.bold ? '600' : '400'};
    line-height: 24px;
    text-align: left;
    color: ${props => props.color ? '#b5b3b3' : props.color};

`;

export const OutlineText = styled.div.attrs({
})`
    font-size: ${props => props.small ? '14px' : '16px'};
    font-weight: ${props => props.bold ? '600' : '400'};
    text-decoration: underline;
    line-height: 24px;
    text-align: left;
    cursor: pointer;
    color: ${props => props.color ? '#b5b3b3' : props.color};

`;

export const ButtonContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: ${props => props.end ? 'flex-end' : 'flex-start'};
    gap: 20px;
    width: 100%;
    padding: 16px 0;
    box-sizing: border-box;
    @media (max-width: 798px) {
        padding: 20px 20px;
    }
`;

export const CardContainer = styled.div.attrs({
})`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
`

export const Card = styled.div.attrs({
})`
border-radius: 8px;
padding: 16px;
cursor: pointer;
width: 100%;
display flex;
gap: 20px;
:hover {
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
}
`

export const CardTextContainer = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
`