import styled from 'styled-components';

export const TermContainer = styled.div.attrs({
 })`
  font-family: Arial, sans-serif;
  line-height: 1.6;
  padding: 20px;
  background-color: #f9f9f9;
`;


export const TermTitle = styled.h1.attrs({
  })`
    font-size: 24px;
    margin-bottom: 20px;
  `;

export const TermSubtitle = styled.h2.attrs({
  })`
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  `;

export const Text = styled.p.attrs({
  })`
    margin: 10px 0;
  `;

export const SignatureSection = styled.div.attrs({
  })`
    margin-top: 30px;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
  `;

export const TermUl = styled.ul.attrs({
  })`
    margin: 10px 0;
    padding-left: 20px;
  `;