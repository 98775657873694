import React, { useEffect, Suspense, lazy } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import ModalCore from "components/Modal/Core";
import CircularProgress from '@mui/joy/CircularProgress';
import Landpage from "screens/Landpage";
import InactivityLogoutTimer from 'components/InactivityLogoutTimer';

const NotFound = lazy(() => import('screens/NotFound'));
const Plans = lazy(() => import('screens/Plans'));
const Support = lazy(() => import('screens/Support'));
const Login = lazy(() => import('screens/Authentication/Login'));
const Register = lazy(() => import('screens/Authentication/Register'));
const RegisterTeacher = lazy(() => import('screens/Authentication/RegisterTeacher'));
const Forgot = lazy(() => import('screens/Authentication/Forgot'));
const CreatePassword = lazy(() => import('screens/Authentication/CreatePassword'));
const DashboardHome = lazy(() => import('screens/Dashboard/Home'));
const DashboardHomeTeacher = lazy(() => import('screens/Dashboard/HomeTeacher'));
const Saying = lazy(() => import('screens/Saying'));
const RankingDashboard = lazy(() => import('screens/Ranking'));
const Profile = lazy(() => import('screens/Profile'));
const ProfileTeacher = lazy(() => import('screens/ProfileTeacher'));
const Settings = lazy(() => import('screens/Settings'));
const SettingsTeacher = lazy(() => import('screens/SettingsTeacher'));
const FAQ = lazy(() => import('screens/FAQ'));
const Students = lazy(() => import('screens/Students'));
const Teachers = lazy(() => import('screens/Teachers'));
const Contact = lazy(() => import('screens/Contact'));
const Checkout = lazy(() => import('screens/Checkout'));
const PixCheckout = lazy(() => import('screens/PixCheckout'));
const Tutorial = lazy(() => import('screens/Tutorial'));
const Balance = lazy(() => import('screens/Balance'));
const SendDict = lazy(() => import('screens/SendDict'));

const SayingReinforcement = lazy(() => import('screens/SayingReinforcement'));

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'pageview',
        page: location.pathname,
      },
    };

    async function sendPageView() {
      const TagManager = await import('react-gtm-module');
      TagManager.default.dataLayer(tagManagerArgs);
    }

    sendPageView();
  }, [location]);

  return null;
};

export default function AppRouter() {

  return (
    <Router>
      <InactivityLogoutTimer />
      <TrackPageView />
      <ModalCore />
      <div>
        <Suspense fallback={
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
                              <CircularProgress
                                color="primary"
                                determinate={false}
                                size="md"
                                value={25}
                                variant="solid"
                              />
                            </div>
                            }>
        <Switch>
          <Route path="/" exact> <Landpage /> </Route>
          <Route path="/plans" exact> <Plans /> </Route>
          <Route path="/support" exact> <Support /> </Route>

          <Route path="/login" exact> <Login /> </Route>
          <Route path="/register" exact> <Register /> </Route>
          <Route path="/register-teacher" exact> <RegisterTeacher /> </Route>
          <Route path="/forgot" exact> <Forgot /> </Route>
          <Route path="/create-password" exact> <CreatePassword /> </Route>

          <Route path="/dashboard" exact> <DashboardHome /> </Route>
          <Route path="/dashboard-teacher" exact> <DashboardHomeTeacher /> </Route>
          <Route path="/dictation" exact> <Saying /> </Route>
          <Route path="/dictation-reinforcement" exact> <SayingReinforcement /> </Route>
          <Route path="/ranking" exact> <RankingDashboard /> </Route>
          <Route path="/profile" exact> <Profile /> </Route>
          <Route path="/profile-teacher" exact> <ProfileTeacher /> </Route>
          <Route path="/settings" exact> <Settings /> </Route>
          <Route path="/settings-teacher" exact> <SettingsTeacher /> </Route>
          <Route path="/faq" exact> <FAQ /> </Route>
          <Route path="/contact" exact> <Contact /> </Route>
          <Route path="/checkout" exact> <Checkout /> </Route>
          <Route path="/pix-checkout" exact> <PixCheckout /> </Route>
          <Route path="/tutorial" exact> <Tutorial /> </Route>
          <Route path="/students" exact> <Students /> </Route>
          <Route path="/teachers" exact> <Teachers /> </Route>
          <Route path="/balance" exact> <Balance /> </Route>
          <Route path="/send-dictation" exact> <SendDict /> </Route>

          <Route path="*" exact> <NotFound /> </Route>
        </Switch>
        </Suspense>
      </div>
    </Router>
  );
}