import Check from "components/Form/Check";
import { TermContainer, TermSubtitle, Text, SignatureSection, TermUl } from "./styled";

export default function Mandarin({checked, setChecked}) {
  return (
    <TermContainer>
    {/* <TermTitle>Termo de Uso e Isenção de Responsabilidade</TermTitle> */}
    <Text><strong>私人教师平台</strong></Text>
    <Text>本文件规定了适用于使用私人教师注册和展示平台（以下简称<strong>“平台”</strong>）的使用条款与免责声明，适用于希望预订私人课程的学生。通过使用本平台并联系注册教师，用户（学生）声明其已阅读、理解并接受以下条款。</Text>

    <TermSubtitle>1. 平台的目的</TermSubtitle>
    <Text>本平台仅为展示工具，用于展示提供联系信息和服务的私人教师的个人资料。平台不参与学生与教师之间的谈判、协议或服务执行的中介、管理或控制。</Text>

    <TermSubtitle>2. 免责声明</TermSubtitle>
    <TermUl>
        <li>平台不对注册教师提供信息的真实性负责，包括但不限于其资格、经验、认证或技术能力。</li>
        <li>学生需自行负责核实教师的身份、信誉和资格，方可签订任何协议或付款。</li>
        <li>学生与教师之间的任何谈判、协议、付款或关系均由双方独自承担风险，与平台无关。</li>
        <li>平台不对因学生与教师关系而产生的争议、误解、违约或其他问题导致的任何物质、道德、经济或其他损失负责。</li>
    </TermUl>

    <TermSubtitle>3. 用户（学生）的责任</TermSubtitle>
    <TermUl>
        <li>学生承诺采取所有必要措施核实教师的信誉，例如进行面试或要求资格证明或推荐信，方可正式签订任何协议。</li>
        <li>学生承认使用平台完全由其自行承担风险，并免除平台因注册教师的行为、疏忽或行为方式而可能承担的任何责任。</li>
    </TermUl>

    <TermSubtitle>4. 条款的接受</TermSubtitle>
    <Text>使用本平台即表示学生明确声明：</Text>
    <TermUl>
        <li>其了解平台仅作为展示空间，不参与学生与教师之间达成的任何谈判或协议；</li>
        <li>其理解确认协议条件并承担所有相关风险是其责任；</li>
        <li>其免除平台因与教师关系而产生的任何问题或损失的任何责任。</li>
      </TermUl>
      <SignatureSection>
        <Text><strong>用户（学生）声明</strong></Text>
        <Check success onChange={() => setChecked(!checked)}
            checked={checked}
            label={
              <Text>我声明我已阅读、理解并完全接受上述条款。我承认平台不对我与教师之间可能产生的任何商业协议或问题负责，我自行承担与注册教师聘用相关的所有风险和责任。</Text>
            }
        />
      </SignatureSection>
      </TermContainer>
  )
}