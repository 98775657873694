import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom';

import {
    HeaderContainer,
    Logo,
    IconContent,
    HeaderContent,
    Text,
} from './styled';

import {

    Icon
} from 'ui/styled';
import Select from 'components/Form/Select';
import SelectDrawer from 'components/Form/SelectDrawer';
import { optionsLanguage } from 'utils/options';
import useI18n from 'hooks/useI18n';
import useWindowSize from "utils/useWindowSize";
import MenuIcon from "components/MenuIcon";

export default function Header() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { width } = useWindowSize()

    const {
        t,
        language,
        setLanguage
    } = useI18n()

    const [drawerOpened, setDrawerOpened] = useState(false)

    useEffect(() => {
        if (width >= 738) {
          setDrawerOpened(false)
        }
    }, [width])

    useEffect(() => {
      if (drawerOpened) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    
      return () => {
        document.body.style.overflow = '';
      };
    }, [drawerOpened]);

    return (
        <>
            <HeaderContainer>
                <Logo onClick={() => navigate('')}/>
                <HeaderContent>
                    {width < 738 ? (
                      // <DashboardHeaderActionIcon src={`/icons/menu.svg`} alt="menu-icon" onClick={() => setOpened(!opened)} />
                      <MenuIcon setOpened={setDrawerOpened} opened={!drawerOpened}/>
                    ) : (
                      <>
                    <Text className='first-step' onClick={() => navigate('login')}>
                        {/* Já tenho uma conta */}
                        { t('login') }
                    </Text>
                    <Text className='second-step' onClick={() => navigate('register')}>
                        {/* Começar agora */}
                        { t('lg-acc') }
                    </Text>
                    <Text className='third-step' onClick={() => navigate('plans')}>
                        {/* Começar agora */}
                        { t('dashboard_home_see_plans') }
                    </Text>
                    <Select
                        small
                        fitContent
                        border
                        startIcons={
                            <IconContent>
                                <Icon icon='mundi' />
                                {/* <Icon icon={ `${language}`.toLowerCase() } /> */}
                            </IconContent>
                        }
                        width={'155px'}
                        placeholder='Português'
                        options={optionsLanguage}
                        value={language}
                        onChange={setLanguage}
                    />
                    </>
                    )}
                </HeaderContent>
            </HeaderContainer>
            {drawerOpened && (
            <div style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1000,
              background: '#fff',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              marginTop: '88px'
            }}>
              <Text onClick={() => { navigate('login'); setDrawerOpened(false); }}>{t('login')}</Text>
              <Text onClick={() => { navigate('register'); setDrawerOpened(false); }}>{t('lg-acc')}</Text>
              <Text onClick={() => { navigate('plans'); setDrawerOpened(false); }}>{t('dashboard_home_see_plans')}</Text>
              <SelectDrawer
                  placeholder='Português'
                  options={optionsLanguage}
                  value={language}
                  onChange={setLanguage}
              />
            </div>
          )}
        </>
    )
}