import React, { useContext, useMemo } from 'react'
import { ButtonClose, ModalBody, ModalContainer, ModalContent, ModalLabel, ModalTitleContainer, Overlay } from './styled'
import { Icon, Title } from 'ui/styled'
import PlansCard from 'components/PlansCard'
import { CoreContext } from 'context/CoreContext'
import { parseCurrency } from 'utils/parsers'
import useI18n from 'hooks/useI18n'

export default function Plans() {

  const { t } = useI18n(false)

  const { setModal, plans, language } = useContext(CoreContext)

  const parsedPlans = useMemo(() => ([
    ...(plans||[])?.map((m,k) => ({
        ...m,
        "plan": `${ m?.[language] }`,
        // "dictations": "5 ditados ou 7 dias",
        "dictations": `${m?.qtd_ditados} ${ t("dashboard_plan_dict") }`,
        "price": `${t("dashboard_plan_currency")}${parseCurrency(m?.valor||0)}`,
        // "action": "Testar",
        "action": `${ t("dashboard_plan_action") }`,
        famous: k===1,
      }))?.filter(f => f?.status === "ativo")
  ]), [plans])

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
                { t("dashboard_plan_title") }
              <ModalLabel>
                ({ t("dashboard_plan_subtitle") })
              </ModalLabel>
            </Title>
          </ModalTitleContainer>
          <ModalBody>
            {
              parsedPlans.map((plan, index) => (
                <PlansCard
                  key={index}
                  planIndex={index}
                  { ...plan }
                />
              ))
            }

          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </>
  )
}
