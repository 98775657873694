import React, { useContext, useState } from 'react';
import { CardBody, CardContainer, CardHeader, CardLabel, FamousCard, FamousTitle, PlansBody } from './styled';
import Button from 'components/Form/Button';
import { Icon } from 'ui/styled';
import { useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext';
import useI18n from 'hooks/useI18n';
import { handleCheckout } from 'utils/functions';

export default function PlansCard({ plan, dictations, price, price_raw, action, famous, planIndex, chaves_pesquisa, free, lp, teacher }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);
  const { setModal, user, setCheckout, language, pixCheckout, pageType } = useContext(CoreContext)

  const { t } = useI18n(false)

  const [loading, setLoading] = useState(false)

  const handleNavigate = async () => {
    const payload = {
      lookup_key: chaves_pesquisa,
      id: user?.id,
      url: `${window.location.origin}/dashboard`,
      idioma: language,
      valor: price,
      valor_raw: price_raw,
    }

    const response = await fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=d94fd006c5b540e69c94ebe62fb04d01")
    .then(response => response.json())

    if (free) {

      // if (response.country.name === "Brazil") {
      //   setModal({ type: "payments", data: { lp, payload } });
      // }
      // setModal(null);
      if (teacher) {navigate('register?teacher=true'); return;}
      navigate('register');
      return;
    }

    if (response.country.name === "Brazil") {
      if (pixCheckout && pixCheckout.lookup_key === chaves_pesquisa) {
        navigate('pix-checkout');
        return;
      }
      setModal({ type: "payments", data: { lp, payload } });
    } else {
      await handleCheckout({ lp }, setModal, setCheckout, navigate, loading, setLoading, payload)
    }
  }

  return (
    <CardContainer>
      <FamousCard>

        {
          famous && (
            <FamousTitle>
              <Icon icon={'star'} /> { t("dashboard_plan_pop") }
            </FamousTitle>
          )
        }
      </FamousCard>
      <CardHeader plan={(planIndex||0)+1}>
        {plan}
      </CardHeader>
      <PlansBody>
        <CardBody>
          {teacher ? (
            <CardLabel hover onClick={() => setModal({ type: "benefits" })} style={{ cursor: 'pointer' }}>
              {dictations}
            </CardLabel>
          ) : (
            <CardLabel>
              {dictations}
            </CardLabel>
          )}
        </CardBody>
        <CardBody>
          <CardLabel>
            <strong>{price}</strong>
          </CardLabel>
        </CardBody>
      </PlansBody>
      <Button secondary loading={loading} nospace onClick={handleNavigate} disabled={pageType !== 'student' && pageType !== null}>
        {action}
      </Button>
    </CardContainer>
  );
}