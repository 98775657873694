import { CoreContext } from "context/CoreContext";
import { useContext, useState } from "react";
import { ButtonClose, ButtonContainer, ModalBody, ModalContainer, ModalContent, ModalTitleContainer, Overlay } from './styled'
import { Icon, Title } from 'ui/styled'
import Button from 'components/Form/Button'
import useI18n from "hooks/useI18n";

import Portuguese from 'components/GalleryTerms/Portuguese'
import English from 'components/GalleryTerms/English'
import Hindi from 'components/GalleryTerms/Hindi'
import Mandarin from 'components/GalleryTerms/Mandarin'
import French from 'components/GalleryTerms/French'
import Spanish from 'components/GalleryTerms/Spanish'
import German from 'components/GalleryTerms/German'
import Italian from 'components/GalleryTerms/Italian'

import { SaveConsent } from "services/mobdict";
import { exposeMobdictError } from "utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function GalleryConsentTerm() {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const { language, setModal, user, fetchUser } = useContext(CoreContext);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const { t } = useI18n(false);

  const save = async () => {
    setLoading(true)
    const result = await SaveConsent({
      idioma: language,
      email: user?.email,
    }, user?.id)

    setLoading(false)
    if (!exposeMobdictError(result, Object.keys(result.data).length !== 0)) {
      const u = await fetchUser(user?.id)
      setModal(null)
      navigate('teachers')
    }
  }

  return (
      <>
        <Overlay onClick={() => setModal(null)} />
        <ModalContainer>
          <ModalContent>
            <ModalTitleContainer>
              <Title hasIcon>
                <Icon icon='logo-mobdict' width={44} nomargin />
                { t("gallery_consent_modal_title") }
              </Title>
            </ModalTitleContainer>
            <ModalBody>
              {language === 'Portuguese' && <Portuguese checked={checked} setChecked={setChecked}/>}
              {language === 'English' && <English checked={checked} setChecked={setChecked}/>}
              {language === 'Hindi' && <Hindi checked={checked} setChecked={setChecked}/>}
              {language === 'Mandarin' && <Mandarin checked={checked} setChecked={setChecked}/>}
              {language === 'French' && <French checked={checked} setChecked={setChecked}/>}
              {language === 'Spanish' && <Spanish checked={checked} setChecked={setChecked}/>}
              {language === 'German' && <German checked={checked} setChecked={setChecked}/>}
              {language === 'Italian' && <Italian checked={checked} setChecked={setChecked}/>}
              <ButtonContainer>
                <Button secondary width="144px" outline colorBlack
                  onClick={() => setModal(null)}
                >{ t("dashboard_form_cancel") }</Button>
  
                <Button secondary width="144px" loading={loading} disabled={!checked} onClick={save}
                >{ t("dashboard_form_next") }</Button>
              </ButtonContainer>
            </ModalBody>
  
          </ModalContent>
        </ModalContainer>
      </>
    )
}