import { SvgIcon } from "./styled"

export default function MenuIcon({setOpened, opened, noDisplay}) {
  return (
    <SvgIcon viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setOpened(opened)} noDisplay> 
      <path d="M15 3H1" stroke-width="2" stroke-linejoin="round"></path> 
      <path d="M15 8H1" stroke-width="2" stroke-linejoin="round"></path> 
      <path d="M15 13H1" stroke-width="2" stroke-linejoin="round"></path> 
    </SvgIcon>
  )
}