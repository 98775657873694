import { CoreContext } from 'context/CoreContext';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DoLogout } from 'services/authentication';

const InactivityLogoutTimer = () => {
    const location = useLocation();
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { setWords, setRanking, setOpened, setUser, setPlan, setDict, setPlans, setLastKeep, setTestDrive, setPageType, setPixCheckout, handleStateReset, setUserTeacher, setModal } = useContext(CoreContext)

    const checkForInactivity = async () => {
        const expireTimeString = localStorage.getItem('expireTime');
        const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

        const pathnames = ['/', '/Login', '/login', '/register', '/forgot', '/create-password', '/plans']
        if (expireTime < Date.now() && (!pathnames.includes(location.pathname))) {
            await DoLogout()
            
            handleStateReset()
            setUser(null)
            setUserTeacher(null)
            setPlan(null)
            setDict(null)
            setTestDrive(null)
            setPageType(null)
            setPixCheckout(null)
            setModal(null)
            
            setWords([])
            setRanking([])
            setPlans([])
    
            setOpened(false)
            setLastKeep(null)
    
            navigate('login')
        }
    };

    const updateExpiryTime = () => {
        const expireTime = Date.now() +  15 * 60 * 1000 // 15 minutes in milliseconds (15 * 60 * 1000)
        localStorage.setItem('expireTime', expireTime.toString());
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();
        }, 5000); // Check for inactivity every 15 minutes

        return () => clearInterval(interval);
    }, [15 * 60 * 1000]);

useEffect(() => {
    const events = ['click', 'keypress', 'scroll', 'mousemove'];

    const update = () => {
        updateExpiryTime();
    };

    events.forEach(event => window.addEventListener(event, update));

    return () => {
        events.forEach(event => window.removeEventListener(event, update));
    };
}, []);

    return null; 
};

export default InactivityLogoutTimer;