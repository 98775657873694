import React, { useContext } from 'react'
import { ButtonClose, ModalBody, ModalContainer, ModalContent, ModalTitleContainer, Overlay, Text, ButtonContainer } from './styled'
import { Icon, Title } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'
import Button from 'components/Form/Button'
import useI18n from 'hooks/useI18n'

export default function Warning() {

  const { setModal } = useContext(CoreContext)

  const { t } = useI18n(false)

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              {t("plan_teacher_benefits_title")}
            </Title>
          </ModalTitleContainer>
          <ModalBody>
              <Text>{t("plan_teacher_benefits_text")}</Text>
              <ol>
                <li>{t("plan_teacher_benefits_1")}</li>
                <li>{t("plan_teacher_benefits_2")}</li>
                <li>{t("plan_teacher_benefits_3")}</li>
              </ol>
              <Text>{t("plan_teacher_benefits_cta")}</Text>
          <ButtonContainer>
            <Button secondary width="144px" onClick={() => setModal(null)}
            >{ t("dashboard_register_step2_action") }</Button>
          </ButtonContainer>
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </>
  )
}
