import React, { useContext, useState } from 'react'
import { ButtonClose, ModalBody, ModalContainer, ModalContent, ModalTitleContainer, Overlay, Text, OutlineText, CardContainer, Card, CardTextContainer, ButtonContainer } from './styled'
import { Icon, Load, Title } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import useI18n from 'hooks/useI18n'
import Button from 'components/Form/Button';
import { CheckAllNotifications } from 'services/notifications';
import { exposeMobdictError } from 'utils';
import { ReadObject } from 'services/storage';
import { GetTeacherDict } from 'services/mobdict'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function Notifications() {

  const { setModal, user, language, setNotifications, notifications, setDict, setOpened } = useContext(CoreContext)

  const { t } = useI18n(false)

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const [visibleNotifications, setVisibleNotifications] = useState(notifications)
  const [activeAll, setActiveAll] = useState(true)
  const [activeRead, setActiveRead] = useState(false)
  const [loadingId, setLoadingId] = useState(null)

  const tempoDecorrido =(dataISO) => {
    const data = new Date(dataISO);
    const agora = new Date();
  
    const diffEmMs = agora - data;
    const diffEmDias = Math.floor(diffEmMs / (1000 * 60 * 60 * 24));
  
    if (diffEmDias === 0) return t("notifications_modal_time_today");
    if (diffEmDias === 1) return t("notifications_modal_time_one");
    return t("notifications_modal_time_more").replace("**", diffEmDias);
  }

  const showAll = () => {
    setActiveAll(true)
    setActiveRead(false)
    setVisibleNotifications(notifications)
  }

  const showUnread = () => {
    setActiveAll(false)
    setActiveRead(true)
    setVisibleNotifications(notifications.filter((not) => not.lida === 'False'))
  }

  const readAll = async () => {
    const payload = {
      username: user?.codigo_professor,
      idioma: language,
    }

    const result = await CheckAllNotifications(user?.id, payload)

    if( !exposeMobdictError(result, Object.keys(result.data).length !== 0 )){
      const novas = result.data.data
      setNotifications(novas)
      if (activeRead) {
        setVisibleNotifications(novas.filter((not) => not.lida === 'False'))
      } else {
        setVisibleNotifications(novas)
      }
    }
  }

  const goToDict = async (notification) => {
    setLoadingId(notification.ID_Notificacao)
    const auth = await ReadObject('authentication')
    const payload = {
      tipo: auth?.tipo,
      break_time: user?.pausa || 7,
      idioma: language,
      id_ditado: notification.ID_Ditado,
      id_notificacao: notification.ID_Notificacao
    }
    const result = await GetTeacherDict(payload, user?.id)
    if( !exposeMobdictError(result, Object.keys(result.data).length !== 0 )){
      setDict(result?.data)
      setModal(false)
      setOpened(false)
      navigate('dictation')
    }
    setLoadingId(null)
  }

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              {t("notifications_modal_title")}
            </Title>
          </ModalTitleContainer>
          <ModalBody>
              <OutlineText onClick={readAll}>{t("notifications_modal_all_read")}</OutlineText>
              <ButtonContainer>
                <Button secondary={activeAll} width="144px" onClick={showAll}
                  >{ t("notifications_modal_button_1") }</Button>
                <Button secondary={activeRead} width="144px" onClick={showUnread}
                >{ t("notifications_modal_button_2") }</Button>
              </ButtonContainer>
              <CardContainer>
              {visibleNotifications.length === 0 ? (
                  <div style={{padding: '16px'}}>
                  <Text>{t("notifications_modal_any")}</Text>
                  </div>
                )
                : (visibleNotifications.map((notification) => (
                <>
                  <Card id={notification.ID_Notificacao} onClick={() => goToDict(notification)}>
                    {loadingId === notification.ID_Notificacao ? (
                      <div style={
                        {
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%'
                        }
                      }><Load newColor/></div>) : (
                        <>
                        <NewReleasesIcon fontSize='large' sx={{'color': notification.lida === 'False' ? '#00DCCF' : '#b5b3b3'}}/>
                        <CardTextContainer>
                        <Text>{t("notifications_modal_message")}</Text>
                        <Text bold color small>{tempoDecorrido(notification.criada_em)}</Text>
                        </CardTextContainer>
                        </>
                    )}
                  </Card>
                </>
              )))}
              </CardContainer>
              <ButtonContainer end>
                <Button secondary width="144px" onClick={() => setModal(null)}
                >{ t("dashboard_register_step2_action") }</Button>
              </ButtonContainer>
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </>
  )
}
