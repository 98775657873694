export const static_translation = {
    "bar_chart_general": "General",
    "bar_chart_students": "Tus estudiantes",
    "dashboard_audio_player_ditado": "Pausado",
    "dashboard_audio_player_original": "Original",
    "dashboard_become_teacher": "Activar Perfil de Profesor",
    "dashboard_create_new_password_check1_text": "Letra mayúscula",
    "dashboard_create_new_password_check2_text": "Número",
    "dashboard_create_new_password_check3_text": "Al menos 6 caracteres",
    "dashboard_create_new_password_check4_text": "Carácter especial",
    "dashboard_create_new_password_step1_text": "Introduzca una contraseña",
    "dashboard_create_new_password_step2_text": "Repite la contraseña",
    "dashboard_createpassword_step1_text": "Ingrese una nueva contraseña para su cuenta",
    "dashboard_createpassword_step1_title": "Nueva contraseña",
    "dashboard_createpassword_step2_text": "Ahora puedes iniciar sesión con tu nueva contraseña",
    "dashboard_createpassword_step2_title": "Nueva contraseña creada con éxito",
    "dashboard_dict_added_increase": "Has añadido más",
    "dashboard_dict_added_words": "palabras a tu vocabulario.",
    "dashboard_dict_added_words_singular": "palabra a tu vocabulario.",
    "dashboard_dict_back_homepage": "Volver a la página principal",
    "dashboard_dict_congrats": "¡Felicidades!",
    "dashboard_dict_obs": "Observaciones",
    "dashboard_dict_restart": "Iniciar nuevo dictado",
    "dashboard_dict_see_obs": "Ver observaciones",
    "dashboard_dict_see_translate": "Ver traducción",
    "dashboard_dict_translate": "Traducción",
    "dashboard_dict_writing_handed": "Escritura a mano",
    "dashboard_dict_writing_meta": "Meta",
    "dashboard_dict_writing_progress": "Tu progreso",
    "dashboard_dict_writing_special": "Caracteres especiales",
    "dashboard_dict_writing_step_1": "1. Descargue la aplicación Mobdict Image Sender de la App Store o Play Store e inicie sesión.",
    "dashboard_dict_writing_step_1_web": "1. Escanea el código QR al lado. Serás redirigido al sitio web de Mobdict Image Sender.",
    "dashboard_dict_writing_step_2": "2. Escriba el dictado que escuchó en un papel y tome una foto.",
    "dashboard_dict_writing_step_2_web": "2. Escribe a mano el dictado que escuchaste y toma una foto.",
    "dashboard_dict_writing_step_3": "3. Envíe la imagen a través de la aplicación y haga clic en el botón de al lado para finalizar.",
    "dashboard_dict_writing_step_3_web": "3. Envía la imagen a través del sitio web y haz clic en el botón al lado para finalizar.",
    "dashboard_dict_writing_step_action": "Ya enviado",
    "dashboard_dict_writing_step_confirm": "Ya he comprobado, Corregir",
    "dashboard_dict_writing_title": "Escriba el texto",
    "dashboard_dict_writing_words": "palabras",
    "dashboard_dict_writing_words_singular": "palabra",
    "dashboard_dict_wrong_challange": "Desafío",
    "dashboard_dict_wrong_finish": "Finalizar",
    "dashboard_dict_wrong_omit": "palabras omitidas",
    "dashboard_dict_wrong_omit_singular": "palabra omitida",
    "dashboard_dict_wrong_reinforcement": "Hacer dictado de refuerzo",
    "dashboard_dict_wrong_words": " palabras están incorrectas",
    "dashboard_dict_wrong_words_singular": "palabra está incorrecta",
    "dashboard_forgot_step1_text": "Introduce tu correo registrado para recuperar tu contraseña",
    "dashboard_forgot_step1_title": "¿Olvidaste tu contraseña?",
    "dashboard_forgot_step1_title_afirm": "Olvidé mi contraseña",
    "dashboard_forgot_step2_text": "Accede al enlace de recuperación que fue enviado a tu correo registrado",
    "dashboard_forgot_step2_title": "Verifica tu correo electrónico",
    "dashboard_form_account_title": "Mi cuenta",
    "dashboard_form_back": "Volver",
    "dashboard_form_balance_title": "Control de créditos",
    "dashboard_form_cancel": "Cancelar",
    "dashboard_form_confirm_password": "Repita la contraseña",
    "dashboard_form_delete_checkbox": "Eliminación de cuenta",
    "dashboard_form_delete_confirmar": "Confirmar",
    "dashboard_form_delete_msg": "Después de la confirmación, su solicitud será enviada a nuestro equipo. La eliminación de la cuenta y de sus datos se completará dentro de las 72 horas de forma definitiva e irreversible. Una vez finalizado el proceso, recibirá un correo electrónico de confirmación. Al hacer clic en 'Continuar', acepta estos términos.",
    "dashboard_form_email": "Correo electrónico",
    "dashboard_form_level": "Nivel",
    "dashboard_form_name": "Nombre completo",
    "dashboard_form_next": "Continuar",
    "dashboard_form_nickname": "Elige tu nombre de usuario (Apodo)",
    "dashboard_form_password": "Contraseña",
    "dashboard_form_password_title": "Cambiar contraseña",
    "dashboard_form_password_validation": "La nueva contraseña y la confirmación de la nueva contraseña no son iguales",
    "dashboard_form_phone": "Teléfono móvil",
    "dashboard_form_save": "Guardar",
    "dashboard_form_subgender": "Seleccione los subgéneros literarios",
    "dashboard_form_teacher": "Código del Profesor",
    "dashboard_home_cta": "Empecemos el dictado",
    "dashboard_home_dicts_days": "días restantes",
    "dashboard_home_dicts_dict": "Dictados",
    "dashboard_home_dicts_done": "realizados",
    "dashboard_home_dicts_plan": "Dictados contratados",
    "dashboard_home_dicts_test": "Período De Prueba",
    "dashboard_home_dicts_test_done": "Período de prueba finalizado",
    "dashboard_home_learning": "Estoy aprendiendo",
    "dashboard_home_level": "Nivel",
    "dashboard_home_ranking_name": "Nombre",
    "dashboard_home_ranking_points": "Puntuación",
    "dashboard_home_ranking_position": "Posición",
    "dashboard_home_ranking_see_more": "Ver más",
    "dashboard_home_ranking_title": "Clasificación",
    "dashboard_home_see_plans": "Ver planes",
    "dashboard_home_teacher": "Profesor",
    "dashboard_no_plans": "Planes disponibles pronto.",
    "dashboard_password_title": "Cree su contraseña",
    "dashboard_plan_action": "Suscribirse",
    "dashboard_plan_benefits": "Ver beneficios",
    "dashboard_plan_currency": "US$",
    "dashboard_plan_dict": "dictados",
    "dashboard_plan_free_action": "Probar",
    "dashboard_plan_name": "Gratis",
    "dashboard_plan_pop": "Más popular",
    "dashboard_plan_subtitle": "cobrado anualmente",
    "dashboard_plan_title": "Suscríbete a uno de nuestros planes",
    "dashboard_qtdade_TD": "prueba gratuita",
    "dashboard_ranking_empty": "Ninguna información encontrada",
    "dashboard_register_and": "y",
    "dashboard_register_consent_term_1": "Autorizo el intercambio de los elementos seleccionados con otros usuarios de la plataforma.",
    "dashboard_register_gallery_term_1": "Deseo aparecer en la galería de profesores de idiomas.",
    "dashboard_register_level": "Seleccione el idioma y el nivel",
    "dashboard_register_step0_student": "Estudiante",
    "dashboard_register_step0_teacher": "Profesor",
    "dashboard_register_step0_text": "Especifique en qué categoría desea registrarse",
    "dashboard_register_step0_title": "Define Tu Perfil",
    "dashboard_register_step1_action": "Continuar",
    "dashboard_register_step1_text": "Proporcione sus datos de registro",
    "dashboard_register_step1_title": "Ingrese sus datos",
    "dashboard_register_step2_action": "Cerrar",
    "dashboard_register_step2_back": "Volver",
    "dashboard_register_step2_text": "Gracias por registrarte.",
    "dashboard_register_step2_title": "Registro completado con éxito",
    "dashboard_register_subgender": "Seleccione los subgéneros literarios",
    "dashboard_register_subtitle": "Ingrese sus datos de registro",
    "dashboard_register_successfull": "Cuenta creada con éxito",
    "dashboard_register_teacher_code": "Identifica a tu profesor",
    "dashboard_register_teacher_step1_text": "Proporcione sus redes sociales y una foto de perfil",
    "dashboard_register_teacher_step1_title": "Redes Sociales",
    "dashboard_register_terms_1": "Al seleccionar y continuar, usted acepta nuestros",
    "dashboard_register_terms_2": "Términos de servicio",
    "dashboard_register_terms_3": "Política de privacidad",
    "dashboard_register_title": "Ingrese sus datos",
    "dashboard_register_validation_accept_terms": "Aceptar los términos",
    "dashboard_register_validation_email": "Ingrese el correo electrónico",
    "dashboard_register_validation_not_match": "La nueva contraseña y la confirmación no coinciden",
    "dashboard_register_validation_password": "Ingrese la nueva contraseña",
    "dashboard_register_validation_password_confirmation": "Ingrese la confirmación de la nueva contraseña",
    "dashboard_send_dict_all": "Todos",
    "dashboard_send_dict_students_clean": "Limpiar",
    "dashboard_send_dict_students_subtitle": "Elige a qué estudiantes deseas enviar este dictado",
    "dashboard_send_dict_students_title": "Seleccionar estudiantes",
    "dashboard_send_dict_title": "Escribe tu dictado",
    "dashboard_settings_2fa": "Habilitar la autenticación de dos factores",
    "dashboard_settings_active": "Activo en la galería",
    "dashboard_settings_adjust_pause": "Ajustar la pausa en el dictado",
    "dashboard_settings_consent": "Restablecer permisos",
    "dashboard_settings_consent_title": "Seleccione los permisos.",
    "dashboard_settings_default_lang": "Idioma predeterminado",
    "dashboard_settings_define": "Definir",
    "dashboard_settings_languages": "Restablecer idiomas de enseñanza",
    "dashboard_settings_learn_lang": "Idioma de aprendizaje",
    "dashboard_settings_native_lang": "Idioma nativo",
    "dashboard_settings_pause": "Pausa",
    "dashboard_settings_recurrency": "Suscripción recurrente",
    "dashboard_settings_subgender": "Restablecer los subgéneros literarios",
    "dashboard_settings_title": "Configuraciones",
    "dashboard_side_account": "Mi cuenta",
    "dashboard_side_balance": "Balance",
    "dashboard_side_buy": "Agregar Dictados",
    "dashboard_side_dict": "Publicar dictado",
    "dashboard_side_exit": "Salir",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_home": "Inicio",
    "dashboard_side_ranking": "Clasificación",
    "dashboard_side_settings": "Configuraciones",
    "dashboard_side_students": "Mis estudiantes",
    "dashboard_side_talk_us": "Contáctenos",
    "dashboard_side_teachers": "Galería de Profesores",
    "dashboard_side_tutorial": "Tutoriales",
    "dashboard_subtitle_subgender": "Mínimo 3",
    "dashboard_table_balance_1": "Dictados Contratados",
    "dashboard_table_balance_2": "Dictados Generados Y No Realizados",
    "dashboard_table_balance_3": "Dictados Realizados",
    "dashboard_table_balance_4": "Dictados Disponibles",
    "dashboard_table_balance_desc": "Descripción",
    "dashboard_table_balance_qtd": "Cantidad",
    "dashboard_talkus_about_text": "Somos apasionados por los idiomas y la educación. Nuestro objetivo es hacer que el aprendizaje sea accesible y divertido para todos, utilizando un enfoque innovador basado en dictados. En Mobdict®, ¡transformar tu aprendizaje de idiomas es más fácil y divertido con dictados! Estas pequeñas perlas de sabiduría ofrecen una forma única de absorber la cultura y el lenguaje. Al aprender un nuevo idioma, descubre refranes que reflejan la forma de pensar y vivir de los hablantes nativos. Cada expresión trae una lección valiosa y contexto cultural, ayudándote a dominar matices y a sonar más natural.",
    "dashboard_talkus_about_title": "Quiénes somos",
    "dashboard_talkus_email": "Correo electrónico",
    "dashboard_talkus_support_address": "Dirección",
    "dashboard_talkus_support_email": "Soporte técnico",
    "dashboard_talkus_support_social": "Redes sociales",
    "dashboard_talkus_title": "Contáctenos",
    "dashboard_teacher_above_avg": "por encima del promedio general",
    "dashboard_teacher_average": "en promedio",
    "dashboard_teacher_below_avg": "por debajo del promedio general",
    "dashboard_teacher_dicts_title": "Dictados Realizados por Estudiante",
    "dashboard_teacher_show_more": "Ver más",
    "dashboard_teacher_words_title": "Palabras Asimiladas por Estudiante",
    "dashboard_teacher_wrong_subtitle": "Ocurrencias",
    "dashboard_teacher_wrong_title": "Palabras más Erróneas - Tus estudiantes",
    "dashboard_teacher_wrong_title_general": "Palabras más Erróneas - General",
    "dict_a_newdict": "Iniciar un nuevo dictado",
    "dict_by": "Dictado por: ",
    "dict_correct": "Corregir",
    "dict_finish": "Finalizar",
    "dict_form_dict": "Dictado",
    "dict_form_dict_error": "Inserte el texto.",
    "dict_form_obs": "Observaciones",
    "dict_form_ref": "Referencia (escribe 'N/A' si no tienes una)",
    "dict_form_reference_error": "Inserte la referencia.",
    "dict_form_select_error": "Seleccione al menos un estudiante.",
    "dict_next": "Continuar",
    "dict_reference": "Referencia:",
    "dict_reinforcement": "Dictado de refuerzo",
    "dict_word_bellow": "Utiliza las palabras de abajo para completar el texto",
    "faq_title": "FAQ - Preguntas frecuentes",
    "footer_all_rights": "Todos los derechos reservados",
    "footer_support": "Soporte",
    "footer_talk_us": "Contáctenos",
    "gallery_consent_modal_title": "Términos de Uso y Exención de Responsabilidad",
    "gallery_info_title": "Información de Contacto",
    "gallery_languages": "Idiomas",
    "gallery_score": "Puntuación",
    "galley_student_count": "estudiante en Mobdict",
    "galley_students_count": "alumnos en Mobdict",
    "helpicon_languages": "Marque los idiomas que enseña",
    "helpicon_level": "Niveles de competencia en el aprendizaje de idiomas, según el Marco Común Europeo de Referencia para las Lenguas (MCER)",
    "helpicon_native_lang": "Las traducciones serán en este idioma.",
    "helpicon_score": "La puntuación representa el promedio de palabras asimiladas por alumno de este profesor",
    "helpicon_subgender": "Los refranes se crearán en función de estas elecciones, haciéndolos más interesantes y alineados con tus preferencias.",
    "helpicon_teacher": "Si ya tiene un profesor, ingrese el código proporcionado o solicítelo directamente a él.",
    "helpicon_username": "Esta información será visible para otros usuarios de Mobdict.",
    "Infantojuvenil": "Infantil y juvenil",
    "landpage_banner_balloon": "¡Mejora un nuevo idioma!",
    "landpage_download_button": "Descarga disponible en",
    "landpage_flags_title": "Mejora un idioma con Mobdict®",
    "landpage_free_action": "Quiero probar",
    "landpage_free_text": "Puedes practicar el idioma que estás estudiando, en el nivel que te encuentras, probando Mobdict® gratuitamente por una semana y haciendo hasta 5 dictados para ver tu vocabulario aumentar con cada desafío.",
    "landpage_free_title": "Haz una prueba gratuita",
    "landpage_how_works_action": "Quiero conocer los planes",
    "landpage_how_works_text": "Somos apasionados por los idiomas y la educación. Nuestro objetivo es hacer que el aprendizaje sea accesible y divertido para todos, utilizando un enfoque innovador basado en dictados.",
    "landpage_how_works_title": "Nuestra mayor satisfacción es ayudar en el aprendizaje de un nuevo idioma",
    "landpage_learn_action": "Comenzar ahora",
    "landpage_learn_text": "Nuestro método de enseñanza se concentra en practicar idiomas a través de dictados, una técnica que combina comprensión auditiva, lectura y práctica escrita para mejorar tus habilidades lingüísticas de forma natural y eficaz.",
    "landpage_learn_title": "Aprende mucho más 😎",
    "landpage_more_text": "Mira nuestro video para entender cómo el aprendizaje de idiomas utilizando dictados puede beneficiarte.",
    "landpage_more_title": "Aprende de la mejor manera 🎬",
    "landpage_write_text": "Escribir a mano puede mejorar la retención y comprensión del vocabulario, ya que involucra procesos cognitivos más profundos. Envía tus manuscritos a Mobdict® usando la app Mobdict Image Sender.",
    "landpage_write_title": "Escribe a mano y aprende más con Mobdict®",
    "lg-acc": "Regístrate",
    "lg-acc-txt": "¿Aún no tienes una cuenta? <a id=\"lg-acc\" href=\"/apl/register\">Regístrate</a>",
    "lg-auth-text": "Con la aplicación <strong>Google Authenticator</strong>, u otra aplicación de autenticación, escanea el QRCode a continuación para generar tu token",
    "lg-email": "Correo electrónico",
    "lg-error-pc": "Rellena todos los campos",
    "lg-error-pc-cloudflare": "Espere la verificación o marque la casilla de Cloudflare.",
    "lg-error-pc-token": "Rellena el campo",
    "lg-fgp": "¿Olvidaste tu contraseña?",
    "lg-fgpx": "¿Olvidaste tu contraseña?",
    "lg-google-auth": "Ingrese el código disponible en la aplicación de autenticación",
    "lg-idioma": "Idioma",
    "lg-legend-login": "Inicio de Sesión",
    "lg-legend-token": "Introduce tu token",
    "lg-senha": "Contraseña",
    "lg-token": "Token",
    "login": "Iniciar sesión",
    "login_mfa": "Utilice la aplicación de autenticación de su preferencia y escanee el código QR a continuación para generar su token",
    "login_mfa_qrcode": "Generar nuevo código QR",
    "login-2": "Autenticando...",
    "login-alt": "Entrar con",
    "login-signup": "¿No tienes una cuenta?",
    "notifications_modal_all_read": "Marcar todo como leído",
    "notifications_modal_any": "No tienes notificaciones.",
    "notifications_modal_button_1": "Todas",
    "notifications_modal_button_2": "No leídas",
    "notifications_modal_message": "¡Nuevo dictado disponible!",
    "notifications_modal_time_more": "Hace ** días",
    "notifications_modal_time_one": "Hace 1 día",
    "notifications_modal_time_today": "Hoy",
    "notifications_modal_title": "Notificaciones",
    "payment_promo_code": "Código promocional",
    "payments_copy_paste": "Pix Copia e Cola",
    "payments_copy_paste_tooltip": "Copiado",
    "pix_error": "Erro ao gerar Qr Code Pix",
    "pix_success": "Cobrança Pix criada com sucesso",
    "plan_teacher_benefits_1": "Experiencia práctica – Realiza hasta 5 dictados gratuitos para explorar la herramienta desde el punto de vista de tus alumnos.",
    "plan_teacher_benefits_2": "Seguimiento del progreso – Después de validar tu perfil como profesor, sigue el desarrollo de tus alumnos suscriptores en tiempo real.",
    "plan_teacher_benefits_3": "Visibilidad y oportunidades – Promociona tus servicios y redes sociales directamente en la plataforma, conectándote con alumnos que buscan cursos o clases particulares.",
    "plan_teacher_benefits_cta": "¡Únete a Mobdict y amplía tus posibilidades como educador!",
    "plan_teacher_benefits_text": "Al registrarte en la plataforma, tendrás acceso a funciones exclusivas para optimizar tu experiencia y potenciar el aprendizaje de tus alumnos.",
    "plan_teacher_benefits_title": "Beneficios para profesores en Mobdict",
    "plans_form_payments": "Forma de pago",
    "plans_payment_card": "Tarjeta de Crédito",
    "register_validation_language": "Seleccione al menos un idioma.",
    "register_validation_subgender": "Seleccione al menos 3 subgéneros para continuar",
    "search_label_teachers": "Buscar un profesor",
    "search_placeholder": "Buscar...",
    "settings_saved": "Cambios realizados. Haz clic en 'Continuar' para guardarlos.",
    "settings_teacher_warning": "El profesor seleccionado no enseña este idioma. Por favor, ingresa otro código (deja en blanco si deseas continuar sin un profesor).",
    "settings_teacher_warning_error": "Profesor o idioma no modificados.",
    "settings_teacher_warning_title": "Cambia tu profesor",
    "students_dict": "Dictados realizados",
    "students_known": "Palabras asimiladas:",
    "students_label_green": "Palabra asimilada",
    "students_label_red": "Palabra no asimilada",
    "students_languages": "Idiomas de aprendizaje:",
    "students_title": "Mis Alumnos",
    "students_wrong": "Palabras más erradas:",
    "teacher_cv_title": "Proporcione un breve currículum",
    "teacher_form_city": "Ciudad",
    "teacher_form_country": "País",
    "teacher_form_cv": "Currículum",
    "teacher_form_facebook": "Facebook",
    "teacher_form_instagram": "Instagram",
    "teacher_form_language": "Idiomas enseñados",
    "teacher_form_linkedin": "LinkedIn",
    "teacher_form_photo": "Foto",
    "teacher_form_social": "Redes sociales",
    "teacher_form_state": "Unidad federativa",
    "teacher_location_title": "Nacionalidad",
    "teacher_pix_checkout_error": "No puede suscribirse a un plan como Profesor.",
    "teacher_register_consent_error": "Seleccione al menos una forma de contacto (correo electrónico o teléfono)",
    "teacher_register_consent_location": "Nacionalidad",
    "teacher_register_consent_term_error": "Acepta el término",
    "teacher_register_info_1": "La información de su perfil será visible para todos los usuarios. Elija cuáles desea compartir:",
    "teacher_register_info_2": "ATENCIÓN: Después de activar su perfil como Profesor, ya no podrá realizar dictados en la plataforma, pero podrá realizar un seguimiento del rendimiento de sus estudiantes.",
    "teacher_register_language_error": "Ingrese al menos un idioma",
    "teacher_register_languages": "Idiomas",
    "teacher_register_languages_subtitle": "Marque los idiomas que enseña",
    "teacher_register_photo_error": "Error al guardar la imagen",
    "teacher_register_photo_type_error": "Formato inválido. Usa png, jpeg o jpg",
    "teacher_students_subtitle": "Leyenda",
    "teachers_title": "Galería de Profesores",
    "title": "Bienvenidos",
    "val-token": "Validar",
    "val-token-2": "Validando...",
    "x26": "Al seleccionar y continuar, usted acepta nuestros Términos de servicio, Política de privacidad y LGPD",
    "x9": "Al seleccionar y continuar, usted acepta nuestros Términos de servicio y Política de privacidad"
}