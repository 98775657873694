import { GET, POST, POST_FORMDATA } from './api'
import { SaveObject } from './storage';

export const ReadTranslation = async (params) => {
    return await POST(`/traducoes`, params);
}

// export const CheckEmail = async (params) => {
//     return await POST(`/verifica-email`, params);
// }

export const AskForgot = async (params) => {
    return await POST(`/usuario/email-senha`, params);
}

export const CompleteForgot = async (params) => {
    return await POST(`/usuario/trocar-senha`, params);
}

export const ValidateRegister = async (code) => {
    return await GET(`/usuario/validate_email?code=${code}`);
}

export const RegisterTeacher = async (params, id) => {
    return await POST(`/professor/cadastrar/${id}`, params, true);
}

export const RegisterUser = async (params) => {
    return await POST(`/usuario/aluno/cadastrar`, params);
}

export const RegisterUserGoogle = async (params) => {
    return await POST(`/usuario/google/cadastrar`, params);
}

export const LoginUser = async (params) => {
    const response = await POST(`/usuario/login`, params);
	if (response?.data?.access_token && response?.data?.mfa !== "True") {
		await SaveObject('authentication', response?.data)
	}
    
    return response
}
export const LoginUserKey = async (params) => {
    const response = await POST(`/usuario/login/chave`, params);
	if (response?.data?.access_token) {
		await SaveObject('authentication', response?.data)
	}
    
    return response
}

export const LoginUserMFA = async (params) => {
    return await POST(`/usuario/login/chave`, params);
}

export const LoginUserGoogle = async (params) => {
    const response = await POST(`/google/login`, params);
    
    return response
}

// authenticated ?

export const ReadUserTeacher = async (id, lang) => {
    return await GET(`/professor/${id}?idioma=${lang}`, true);
}

export const ReadUser = async (id, lang) => {
    return await GET(`/usuario/${id}?idioma=${lang}`, true);
}

export const UpdateUser = async (params, id) => {
    return await POST(`/usuario/${id}`, params, true);
}

export const UpdateUserTeacher = async (params, id) => {
    return await POST(`/professor/${id}`, params, true);
}

export const UpdateTeacherSettings = async (params, id) => {
    return await POST(`/professor/config/${id}`, params, true);
}

export const DeleteUser = async (id, lang) => {
    return await GET(`/usuario/delete/${id}?idioma=${lang}`, true);
}

export const ReadPlanStatusByUserID = async (id, lang) => {
    return await GET(`/status/${id}?idioma=${lang}`, true);
}

export const ReadPlans = async (lang) => {
    return await GET(`/plans?idioma=${lang}`);
}

export const ReadTestDriveByUserID = async (id, lang) => {
    return await GET(`/testdrive/${id}?idioma=${lang}`, true);
}

export const ValidateUserPlanByUserID = async (params, id) => {
    return await POST(`/ditado/validar_plano/${id}`, params, true);
}

export const ReadRankingByUserID = async (params, id) => {
    return await POST(`/ranking/${id}`, params, true);
}

export const CreateDictByUserId = async (params, id) => {
    return await POST(`/ditado/criar/${id}`, params, true);
}

export const GetImage = async (id) => {
    return await GET(`/get-presigned-url/Aceitas/${id}?bucket=mobdict-photos`, true , false);
}

export const GetDictAudio = async (id) => {
    return {url: `https://backend.mobdict.com/api/audios/${id}`}
    //return await GET(`/get-presigned-url/audio_ditado/${id}?bucket=mobdict-audios`, true , true);
}

export const GetDictAudioOriginal = async (id) => {
    return {url: `https://backend.mobdict.com/api/audios/${id}`}
    //return await GET(`/get-presigned-url/audio_original/${id}?bucket=mobdict-audios`, true , true);
}

export const GetDictAudioReinforcement = async (id) => {
    return {url: `https://backend.mobdict.com/api/audios/${id}`}
    //return await GET(`/get-presigned-url/audio_refor%C3%A7o/${id}?bucket=mobdict-audios`, true , true);
}

export const SentDictByUserId = async (params, id) => {
    return await POST(`/ditado/enviar/texto/${id}`, params, true);
}

export const ReadWordsByUserId = async (id, lang) => {
    return await GET(`/ditado/conhecidas/${id}?idioma=${lang}`, true);
}

export const ValidateImageByUserId = async (id, lang) => {
    return await GET(`/ditado/validar/imagem/${id}?idioma=${lang}`, true);
}

export const SentImageByUserId = async (params, id) => {
    return await POST(`/ditado/enviar/imagem/${id}`, params, true);
}

export const ReadInfos = async (language) => {
    return await GET(`/info?idioma=${language}`);
}

export const ReadLanguages = async (language) => {
    return await GET(`/info/idioma?idioma=${language}`);
}

export const ReadSchools = async (id) => {
    return await GET(`/info/escolas`, true);
}

export const UpdatePauseByUserId = async (params, id) => {
    return await POST(`/pausa/${id}`, params, true);
}

export const UpdateReforceByUserId = async (params, id) => {
    return await POST(`/reforco/${id}`, params, true);
}

export const ReadTextByUserID = async (params, id) => {
    return await POST(`/texto/${id}`, params, true);
}

export const CreateCheckout = async (params) => {
    return await POST_FORMDATA(`/create-checkout-session`, params, true);
}

export const CancelSubscriptionByUserID = async (params, id) => {
    return await POST(`/cancel-subscription/${id}`, params, true);
}

export const GenerateQrCode = async (params) => {
  return await POST(`/qrcode/`, params, false)
}

export const GetPhoneCode = async (params) => {
  return await POST(`/phone`, params, false)
}

export const ValidateUserEmail = async (params) => {
  return await GET(`/validate_email?code=${params}`, false)
}

export const ValidateApple = async (params) => {
  return await POST_FORMDATA(`/apple/validar`, params, false);
}

export const LoginApple = async (params) => {
  const response = await POST(`/apple/login`, params, false);
  // if (response?.data?.access_token) {
	// 	await SaveObject('authentication', response?.data)
	// }
    
  return response
}

export const LoginFacebook = async (params) => {
  const response = await POST(`/facebook/login`, params, false);
  // if (response?.data?.access_token) {
	// 	await SaveObject('authentication', response?.data)
	// }
    
  return response
}

export const ReadVideos = async (lang) => {
    return await GET(`/videos?idioma=${lang}`);
}

export const ReadTeachers = async (lang, limit, offset) => {
    return await GET(`/professores?idioma=${lang}&limit=${limit}&offset=${offset}`);
}

export const GetDashboard = async (lang, id, id_prof) => {
    return await GET(`/professor/dashboard/${id}?idioma=${lang}&ID_professor=${id_prof}`, true);
}

export const GetStudents = async (lang, id, id_prof, limit, offset) => {
    return await GET(`/professor/alunos/${id}?idioma=${lang}&ID_professor=${id_prof}&limit=${limit}&offset=${offset}`, true);
}

export const ValidatePhoto = async (params, id) => {
    return await POST(`/foto/${id}`, params, true);
}

export const GeneratePix = async (params, id) => {
  return await POST(`/pix/${id}`, params, true)
}

export const SendPhoto = async (params, id) => {
  return await POST(`/foto/enviar/${id}`, params, true)
}

export const SaveConsent = async (params, id) => {
  return await POST(`/consentimento/${id}`, params, true)
}

export const SendDict = async (params, id) => {
    return await POST(`/professor/ditado/${id}`, params, true)
}

export const GetTeacherDict = async (params, id) => {
  return await POST(`/ditado/prof/${id}`, params, true)
}

export const ValidateCode = async (params, id) => {
  return await POST(`/cupom/${id}`, params, true)
}

export const UpdateCodeCount = async (params) => {
  return await POST(`/cupom/atualizar`, params, false)
}