import React from "react";
import PropTypes from 'prop-types';

import { ButtonIcon, ButtonLabel, ColorButton, Load } from "./styled";
import { ThemedComponent } from "ui/theme";

export const Button = ({
  children,
  loading,
  primary,
  secondary,
  outline,
  link,
  nospace,
  centred,
  onClick,
  colorBlack,
  noWidth,
  large,
  startIcon,
  color,
  width,
  between,
  start,
  endIcon,
  small,
  disabled,
  className,
  id,
}) => {
  return (
    <ThemedComponent>
      <ColorButton
        variant={link ? "text" : outline ? "outlined" : "contained"}
        color={color ? color : (secondary ? 'secondary' : primary ? 'primary' : 'white')}
        large={large}
        nospace={nospace}
        centred={centred}
        onClick={onClick}
        colorBlack={colorBlack}
        noWidth={noWidth}
        startIcon={(!loading && startIcon) && <ButtonIcon icon={startIcon} />}
        // endIcon={(!loading && endIcon) && <ButtonIcon icon={endIcon} />}
        width={width}
        between={between}
        start={start}
        small={small}
        disabled={disabled}
        className={className}
        id={id}
      >
        {loading ? (
          <Load spaced primary={primary} secondary={secondary} outline={outline} />
        ) : (
          <ButtonLabel>
            {children}
          </ButtonLabel>
        )}
      </ColorButton>
    </ThemedComponent>
  );
}

Button.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  outline: PropTypes.bool,
  link: PropTypes.bool,
  nospace: PropTypes.bool,
  centred: PropTypes.bool,
  onClick: PropTypes.func,
  colorBlack: PropTypes.bool,
  noWidth: PropTypes.bool,
  large: PropTypes.bool,
  startIcon: PropTypes.string,
  endIcon: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  between: PropTypes.bool,
  start: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  children: undefined,
  loading: false,
  primary: false,
  secondary: false,
  outline: false,
  link: false,
  nospace: false,
  centred: false,
  onClick: undefined,
  colorBlack: false,
  noWidth: false,
  large: false,
  startIcon: undefined,
  endIcon: undefined,
  color: undefined,
  width: undefined,
  between: false,
  start: false,
  small: false,
  disabled: false,
  className: undefined,
};

export default Button;
