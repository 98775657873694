import Check from "components/Form/Check";
import { TermContainer, TermSubtitle, Text, SignatureSection, TermUl } from "./styled";

export default function English({checked, setChecked}) {
  return (
    <TermContainer>
    {/* <TermTitle>Termo de Uso e Isenção de Responsabilidade</TermTitle> */}
      <Text><strong>Platform for Private Teachers</strong></Text>
      <Text>This document establishes the Terms of Use and Disclaimer applicable to the use of the platform for registering and showcasing private teachers, hereinafter referred to as the <strong>"Platform"</strong>, by students interested in hiring private classes. By using the Platform and contacting the registered teachers, the user (student) declares that they have read, understood, and accepted the terms set forth herein.</Text>

      <TermSubtitle>1. Purpose of the Platform</TermSubtitle>
      <Text>The Platform is exclusively a tool for showcasing, functioning as a gallery of private teacher profiles providing their contact information and services. The Platform does not mediate, manage, or control the negotiations, agreements, or execution of services between students and teachers.</Text>

      <TermSubtitle>2. Disclaimer</TermSubtitle>
      <TermUl>
          <li>The Platform is not responsible for the accuracy of the information provided by registered teachers, including but not limited to their qualifications, experience, certifications, or technical skills.</li>
          <li>The student is solely responsible for verifying the identity, credibility, and qualifications of the teacher before making any arrangements or payments.</li>
          <li>Any negotiation, agreement, payment, or relationship established between the student and the teacher occurs at the sole risk of the involved parties, without generating any responsibility for the Platform.</li>
          <li>The Platform is not liable for material, moral, financial, or any other damages arising from disputes, misunderstandings, non-compliance, or any other issues originating from the relationship between student and teacher.</li>
      </TermUl>

      <TermSubtitle>3. User Responsibility (Student)</TermSubtitle>
      <TermUl>
          <li>The student agrees to take all necessary steps to verify the teacher's credibility, such as conducting interviews and requesting proof of qualifications or references, before formalizing any arrangement.</li>
          <li>The student acknowledges that using the Platform is at their own risk, absolving the Platform of any liability for acts, omissions, or behaviors of the registered teachers.</li>
      </TermUl>

      <TermSubtitle>4. Acceptance of the Terms</TermSubtitle>
      <Text>By using the Platform, the student expressly declares that:</Text>
      <TermUl>
        <li>They are aware that the Platform acts only as a showcasing space and is not a party to any negotiation or agreement established between the student and the teacher;</li>
        <li>They understand it is their responsibility to verify the hiring process and assume all related risks;</li>
        <li>They absolve the Platform of any liability for any problems or damages arising from the relationship with the teacher.</li>
      </TermUl>
      <SignatureSection>
        <Text><strong>Student Declaration</strong></Text>
        <Check success onChange={() => setChecked(!checked)}
            checked={checked}
            label={
              <Text>I hereby declare that I have read, understood, and fully agree to the terms set forth above. I acknowledge that the Platform bears no responsibility for any commercial agreements or issues that may arise between myself and the teacher, assuming full risks and responsibilities associated with hiring registered teachers.</Text>
            }
        />
      </SignatureSection>
      </TermContainer>
  )
}