import styled from 'styled-components';

export const SvgIcon = styled.svg.attrs({
})`
  stroke: #000;
  width: 30px;
  display: ${props => props.noDisplay ? 'none' : 'block'};

  @media (max-width: 991px) {
    display: block;
  }
`;