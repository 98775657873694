import React, { useEffect, useState } from 'react'
import { CheckItem, CheckItemsContent } from './styled'
import Check from 'components/Form/Check'
import useI18n from 'hooks/useI18n';

export default function CheckItems({ options, checked, setChecked, languages, column, consent, students }) {
  
  const { t } = useI18n(false)

  var categorias
  var idMapping = {}
  //if (languages) {
    categorias = [
      ...(options||[]).map((m, index) => {
        idMapping[index + 1] = m.id;
        return m.title;
      }),
    ];

  if (students) {
    categorias = [
      ...(options||[]).map((m, index) => {
        idMapping[index] = m.id;
        return m.title;
      }),
    ];
  }

  // } else {
  //   categorias = [
  //     ...(options||[]).map(m => m.id),
  //   ];
  //   console.log(categorias)
  // }

  const handleCheck = (id) => {
    const actualId = idMapping[id]
    const index = checked.indexOf(actualId);
    if (index > -1) {
      if (actualId === 0 && students) {
        setChecked([])
      } else {
        setChecked(checked.filter(item => item !== actualId))
      }
    } else {
      if (actualId === 0 && students) {
        if (checked.length === categorias.length) {
          setChecked([])
        } else {
          setChecked(options.map(m => m.id))
        }
      } else {
        setChecked([...checked, actualId])
      }
    }
  }

  useEffect(() => {
    if(!languages && checked?.includes(11) && checked?.length > 1){
      setChecked([11])
    }
  }, [checked])

  return (
    <CheckItemsContent column={column}>
      {
        students ? (
          categorias.map((categoria, index) => (
            <CheckItem key={index}>
              <Check id={index} label={categoria} checked={checked?.includes(idMapping[index] || index)} onChange={() => handleCheck(index)} success />
            </CheckItem>
          ))
        ) : (
        categorias.map((categoria, index) => (
          <CheckItem key={index}>
            <Check id={index} label={t(categoria)} disabled={
              (!consent && !languages && checked?.includes(11) && idMapping[index+1] !== 11) ||
              (consent && options.filter(m => m.title === categoria)[0].disabled)
            }
              checked={checked?.includes(idMapping[index+1] || index+1)}
              onChange={() => handleCheck(index+1)}
              success
            />
          </CheckItem>
        )))
      }
    </CheckItemsContent>
  )
}
