import { CoreContext } from "context/CoreContext";
import useI18n from "hooks/useI18n";
import { useContext, useState } from "react";
import { ButtonClose, PaymentsContainer, PaymentItem, ModalBody, ModalContainer, ModalContent, ModalTitleContainer, Overlay, Subtitle, CodeContainer } from './styled'
import { ButtonContainer, Icon, Load, Title } from 'ui/styled'
import PixIcon from '@mui/icons-material/Pix';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { handleCheckout, handlePixCheckout } from "utils/functions";
import Input from "components/Form/Input";
import Button from "components/Form/Button";
import { ValidateCode } from "services/mobdict";
import { exposeMobdictError } from "utils";

export default function Payments({ data }) {
  const { setModal, setCheckout, setPixCheckout, language, user } = useContext(CoreContext)
  const [loading, setLoading] = useState(false)

  const history = useHistory();
  const navigate = to => history.push(to);

  const { t } = useI18n(false)

  const [step, setStep] = useState(data.step || 0)
  const [promoCode, setPromoCode] = useState(null)

  const card = async () => {
    await handleCheckout(data, setModal, setCheckout, navigate, loading, setLoading, data.payload)
  }

  const pix = async (payload) => {
    if (data.lp) {
      setModal(null);
      setPixCheckout({...data.payload, "status": "pending"});
      navigate('login');
      return;
    }
    
    setLoading(true)
    await handlePixCheckout(payload, setPixCheckout, navigate)
    setLoading(false)
    setModal(null);
  }

  const handleClose = () => {
    setModal(null)
  }

  const validateCode = async (chave_pesquisa, id) => {
    const payload = {
      idioma: language,
      email: user?.email,
      cupom: promoCode,
      chave_pesquisa: chave_pesquisa
    }
    const result = await ValidateCode(payload, id)

    if(!exposeMobdictError(result, Object.keys(result.data).length !== 0 )){
        return result.data.desconto
    } 

    return 0
  }

  const applyDiscount = (payload, discountValue) => {
    const newValue = payload.valor_raw * ((100 - discountValue ) / 100)

    payload.valor_raw = parseFloat(newValue.toFixed(2))
    payload.valor = newValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    payload.desconto = discountValue

    return payload
  }

  const next = async () => {
    if (step === 0 && !data.lp) {
      setStep(step + 1)
    } else {
      if (promoCode === null || promoCode === '') {
        let payload = {...data.payload, desconto: 0}
        if (data.id) {
          payload = {...data.payload, id: data.id}
        }
        await pix(payload)
      } else {
        setLoading(true)
        let payload = data.payload
        if (data.id) {
          payload = {...data.payload, id: data.id}
        }
        const discountValue = await validateCode(payload.lookup_key, payload.id)
        if (discountValue !== 0) {
          const newPayload = applyDiscount(payload, discountValue)
          pix({...newPayload, code: promoCode})
        }
        setLoading(false)
      }
    }
  }

  const back = () => {
    if (!data.step) {
      setStep(step - 1)
    } else {
      setModal(null)
    }
  }


  return (
    <>
      <Overlay onClick={handleClose} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              { t("plans_form_payments") }
              {/* <Subtitle>
                ({ t("dashboard_subtitle_payments") })
              </Subtitle> */}
            </Title>
            {step === 0 && <ButtonClose>
               <Icon icon='close-black' pointer onClick={() => setModal(null)} />
             </ButtonClose>
            }
          </ModalTitleContainer>
          <ModalBody>
          <PaymentsContainer>
              <>
                {step === 0 && (
                  <>
                    <PaymentItem onClick={() => next()}>
                      <PixIcon fontSize="large" />
                      <Subtitle>PIX</Subtitle>
                    </PaymentItem>
                    <PaymentItem onClick={() => card()}>
                      <CreditCardIcon fontSize="large" />
                      <Subtitle>{t("plans_payment_card")}</Subtitle>
                    </PaymentItem>
                  </>
                )}
                {!data.lp && step === 1 && 
                <>
                  <CodeContainer>
                  <Input placeholder={t("payment_promo_code")} id={'promo-code'} value={promoCode} onChange={e => setPromoCode(e.target.value)} />
                  </CodeContainer>
                </>}
              </>
          </PaymentsContainer>
          {!data.lp && step === 1 && <ButtonContainer end space>
              <Button secondary colorBlack outline width="50%" nospace onClick={back}
                      startIcon="chevron-left"
                  >{ t("dashboard_form_back") }</Button>
              <Button secondary width="50%" loading={loading} nospace onClick={next}
                  endIcon="chevron-right"
              >{ t("dashboard_form_next") }</Button>
          </ButtonContainer>}
          </ModalBody>
        </ModalContent>
      </ModalContainer>
    </>
  )
}