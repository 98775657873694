import Check from "components/Form/Check";
import { TermContainer, TermSubtitle, Text, SignatureSection, TermUl } from "./styled";

export default function Portuguese({checked, setChecked}) {
  return (
    <TermContainer>
    {/* <TermTitle>Termo de Uso e Isenção de Responsabilidade</TermTitle> */}
    <Text><strong>Plataforma de Divulgação de Professores Particulares</strong></Text><Text>Este documento estabelece os Termos de Uso e Isenção de Responsabilidade aplicáveis à utilização da plataforma de cadastro e divulgação de professores particulares, doravante denominada <strong>"Plataforma"</strong>, por alunos interessados em contratar serviços de aulas particulares. Ao utilizar a Plataforma e efetuar qualquer contato com os professores cadastrados, o usuário (aluno) declara ter lido, compreendido e aceitado os termos aqui dispostos.</Text><TermSubtitle>1. Objeto da Plataforma</TermSubtitle><Text>A Plataforma é exclusivamente uma ferramenta de divulgação, funcionando como uma galeria de perfis de professores particulares que disponibilizam informações de contato e seus serviços. A Plataforma não realiza qualquer intermediação, gerenciamento ou controle sobre as negociações, contratações e execuções de serviços entre alunos e professores.</Text><TermSubtitle>2. Isenção de Responsabilidade</TermSubtitle><TermUl>
      <li>A Plataforma não se responsabiliza pela veracidade das informações fornecidas pelos professores cadastrados, incluindo, mas não se limitando a, sua qualificação, experiência, certificação ou capacidade técnica.</li>
      <li>O aluno é exclusivamente responsável por certificar-se da identidade, idoneidade e qualificação do professor antes de efetuar qualquer contratação ou pagamento.</li>
      <li>Qualquer negociação, contrato, pagamento ou vínculo estabelecido entre o aluno e o professor ocorre por conta e risco exclusivos das partes envolvidas, não gerando qualquer responsabilidade à Plataforma.</li>
      <li>A Plataforma não se responsabiliza por danos materiais, morais, financeiros ou de qualquer outra natureza decorrentes de disputas, mal-entendidos, inadimplências ou quaisquer outros problemas oriundos da relação entre aluno e professor.</li>
    </TermUl><TermSubtitle>3. Responsabilidade do Usuário (Aluno)</TermSubtitle><TermUl>
        <li>O aluno compromete-se a realizar todas as verificações necessárias quanto à idoneidade do professor, como entrevistas, solicitações de comprovações de qualificações ou referências, antes de formalizar qualquer contratação.</li>
        <li>O aluno reconhece que a utilização da Plataforma é feita por sua conta e risco, isentando a Plataforma de qualquer responsabilidade por atos, omissões ou comportamentos dos professores cadastrados.</li>
      </TermUl><TermSubtitle>4. Aceitação do Termo</TermSubtitle><Text>Ao utilizar a Plataforma, o aluno declara expressamente que:</Text><TermUl>
        <li>Está ciente de que a Plataforma atua apenas como um espaço de divulgação, não sendo parte de qualquer negociação ou contrato estabelecido entre aluno e professor;</li>
        <li>Compreende que é sua responsabilidade certificar-se da contratação e assumir todos os riscos relacionados a ela;</li>
        <li>Isenta a Plataforma de qualquer responsabilidade por eventuais problemas ou prejuízos decorrentes da relação com o professor.</li>
      </TermUl>
      <SignatureSection>
        <Text><strong>Declaração do Usuário (Aluno)</strong></Text>
        <Check success onChange={() => setChecked(!checked)}
            checked={checked}
            label={
              <Text>Declaro que li, compreendi e concordo integralmente com os termos acima dispostos. Reconheço que a Plataforma não tem qualquer responsabilidade sobre os acordos comerciais ou problemas que possam ocorrer entre mim e o professor, assumindo integralmente os riscos e responsabilidades associados à contratação de professores cadastrados.</Text>
            }
        />
      </SignatureSection>
      </TermContainer>
  )
}