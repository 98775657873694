import Check from "components/Form/Check";
import { TermContainer, TermSubtitle, Text, SignatureSection, TermUl } from "./styled";

export default function Spanish({checked, setChecked}) {
  return (
    <TermContainer>
    {/* <TermTitle>Termo de Uso e Isenção de Responsabilidade</TermTitle> */}
    <Text><strong>Plataforma para Profesores Privados</strong></Text><Text>EEste documento establece los Términos de Uso y la Exención de Responsabilidad aplicables al uso de la plataforma para el registro y promoción de profesores privados, en adelante denominada <strong>"Plataforma"</strong>, por parte de estudiantes interesados en contratar clases particulares. Al usar la Plataforma y contactar con los profesores registrados, el usuario (estudiante) declara haber leído, comprendido y aceptado los términos aquí expuestos.</Text><TermSubtitle>1. Propósito de la Plataforma</TermSubtitle><Text>La Plataforma es exclusivamente una herramienta de promoción, funcionando como una galería de perfiles de profesores privados que proporcionan su información de contacto y servicios. La Plataforma no realiza ninguna mediación, gestión o control sobre las negociaciones, acuerdos o ejecuciones de servicios entre estudiantes y profesores.</Text><TermSubtitle>22. Exención de Responsabilidad</TermSubtitle><TermUl>
        <li>La Plataforma no se responsabiliza por la veracidad de la información proporcionada por los profesores registrados, incluyendo, entre otros, sus cualificaciones, experiencia, certificaciones o habilidades técnicas.</li>
        <li>El estudiante es el único responsable de verificar la identidad, credibilidad y cualificaciones del profesor antes de realizar cualquier contratación o pago.</li>
        <li>Cualquier negociación, contrato, pago o relación establecida entre el estudiante y el profesor se realiza bajo el exclusivo riesgo de las partes involucradas, sin generar responsabilidad alguna para la Plataforma.</li>
        <li>La Plataforma no es responsable por daños materiales, morales, financieros o de cualquier otra índole derivados de disputas, malentendidos, incumplimientos o cualquier otro problema originado en la relación entre estudiante y profesor.</li>
    </TermUl><TermSubtitle>3. Responsabilidad del Usuario (Estudiante)</TermSubtitle><TermUl>
        <li>El estudiante se compromete a realizar todas las verificaciones necesarias sobre la credibilidad del profesor, como entrevistas o solicitudes de comprobantes de cualificaciones o referencias, antes de formalizar cualquier contratación.</li>
        <li>El estudiante reconoce que el uso de la Plataforma se realiza bajo su propio riesgo, eximiendo a la Plataforma de cualquier responsabilidad por actos, omisiones o comportamientos de los profesores registrados.</li>
      </TermUl><TermSubtitle>4. Aceptación de los Términos</TermSubtitle><Text>Al usar la Plataforma, el estudiante declara expresamente que:</Text><TermUl>
        <li>Es consciente de que la Plataforma actúa únicamente como un espacio de promoción y no es parte de ninguna negociación o contrato establecido entre el estudiante y el profesor;</li>
        <li>Comprende que es su responsabilidad verificar las condiciones de la contratación y asumir todos los riesgos relacionados;</li>
        <li>Exime a la Plataforma de cualquier responsabilidad por problemas o daños derivados de la relación con el profesor.</li>
      </TermUl>
      <SignatureSection>
        <Text><strong>Declaración del Usuario (Estudiante)</strong></Text>
        <Check success onChange={() => setChecked(!checked)}
            checked={checked}
            label={
              <Text>Declaro haber leído, comprendido y aceptado completamente los términos expuestos anteriormente. Reconozco que la Plataforma no tiene ninguna responsabilidad sobre los acuerdos comerciales o problemas que puedan surgir entre el profesor y yo, asumiendo todos los riesgos y responsabilidades asociados con la contratación de profesores registrados.</Text>
            }
        />
      </SignatureSection>
      </TermContainer>
  )
}