import React, { useContext, useEffect } from "react";
import { CoreContext } from "context/CoreContext";
import { exposeMobdictError } from "utils";
import { ReadTranslation } from "services/mobdict";


export default function useI18n(fetchable = true, language_default = null) {
    
    const { language, setLanguage, translation, setTranslation } = useContext(CoreContext)
    const t = ref => translation?.translations?.[ref] || ref ;

    const loadTranslation = async () => {
        if(language){
            // const result = await ReadTranslation({ idioma: language })
            // if( !exposeMobdictError(result, (!!result?.data?.traducoes) ) ){
            //     //const { static_translation } = (require(`utils/i18n/${language.toLowerCase()}`)||require(`utils/i18n/portuguese`)||({ static_translation:[] }))
            //     const next_translation = {
            //         language: language,
            //         translations:{ ...(result?.data?.traducoes || {}) }
            //     }
            //     setTranslation(next_translation)
            // }//else{
            const { static_translation } = (require(`utils/i18n/${language.toLowerCase()}`)||require(`utils/i18n/portuguese`)||({ static_translation:[] }))
            const next_translation = {
                language: language,
                translations:{ ...static_translation }
            }
            setTranslation(next_translation)
            // }
        }
    }

    useEffect(() => {
      if (language_default && language !== language_default) {
          setLanguage(language_default);
      }
  }, [language_default]);
  
  useEffect(() => {
    const ignoreCache = false
      if ((fetchable && language && language !== translation?.language) || ignoreCache) {
        loadTranslation();
      }
  }, [language, fetchable]);

    return {
        t,
        language,
        translation,
        setLanguage
    }
}