import React, { useContext, useState } from 'react'
import { ButtonClose, ButtonContainer, ModalBody, ModalContainer, ModalContent, ModalTitleContainer, Overlay } from './styled'
import { Icon, Title } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'
import CheckItems from 'components/CheckItems'
import Button from 'components/Form/Button'
import { toast } from 'react-toastify'
import useI18n from 'hooks/useI18n'

export default function Languages() {

  const { setModal, modal } = useContext(CoreContext)
  const [loading] = useState(false)

  const [checked, setChecked] = useState([ ...modal?.selected ])

  const { t } = useI18n(false)
 
  const save = async () => {
    if( checked.length === 0 ){
      toast.error(t("register_validation_language"))
      return ;
    }

    modal?.setFinalChecked(checked)
    setModal(null)
    toast.success(t("settings_saved"))
    }

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              { t("teacher_register_languages") }
            </Title>
          </ModalTitleContainer>
          <ModalBody>
            <CheckItems options={modal?.items||[]} checked={checked} setChecked={setChecked} languages/>
            <ButtonContainer>
              {
                loading ? null :
                  <Button secondary width="144px" outline colorBlack loading={loading}
                    onClick={() => setModal(null)}
                  >{ t("dashboard_form_cancel") }</Button>
              }

              <Button secondary width="144px" loading={loading}
                onClick={save}
              >{ t("dashboard_form_save") }</Button>
            </ButtonContainer>
          </ModalBody>

        </ModalContent>
      </ModalContainer>
    </>
  )
}
