import Check from "components/Form/Check";
import { TermContainer, TermSubtitle, Text, SignatureSection, TermUl } from "./styled";

export default function German({checked, setChecked}) {
  return (
    <TermContainer>
    {/* <TermTitle>Termo de Uso e Isenção de Responsabilidade</TermTitle> */}
    <Text><strong>Plattform zur Vermittlung von Privatlehrern</strong></Text>
    <Text>Dieses Dokument legt die Nutzungsbedingungen und den Haftungsausschluss für die Nutzung der Plattform zur Registrierung und Vermittlung von Privatlehrern, im Folgenden <strong>"Plattform"</strong> genannt, durch Schüler fest, die an der Buchung von Privatunterricht interessiert sind. Durch die Nutzung der Plattform und die Kontaktaufnahme mit den registrierten Lehrern erklärt der Nutzer (Schüler), dass er die nachstehenden Bedingungen gelesen, verstanden und akzeptiert hat.</Text>

    <TermSubtitle>1. Zweck der Plattform</TermSubtitle>
    <Text>Die Plattform ist ausschließlich ein Vermittlungsinstrument und dient als Galerie von Profilen von Privatlehrern, die ihre Kontaktdaten und Dienstleistungen anbieten. Die Plattform übernimmt keine Vermittlung, Verwaltung oder Kontrolle der Verhandlungen, Vereinbarungen oder der Durchführung von Dienstleistungen zwischen Schülern und Lehrern.</Text>

    <TermSubtitle>2. Haftungsausschluss</TermSubtitle>
    <TermUl>
        <li>Die Plattform übernimmt keine Verantwortung für die Richtigkeit der von registrierten Lehrern bereitgestellten Informationen, einschließlich, aber nicht beschränkt auf ihre Qualifikationen, Erfahrungen, Zertifikate oder technischen Fähigkeiten.</li>
        <li>Der Schüler ist allein dafür verantwortlich, die Identität, Glaubwürdigkeit und Qualifikation des Lehrers zu überprüfen, bevor er eine Vereinbarung trifft oder Zahlungen leistet.</li>
        <li>Jede Verhandlung, Vereinbarung, Zahlung oder Beziehung, die zwischen dem Schüler und dem Lehrer zustande kommt, erfolgt auf eigenes Risiko der beteiligten Parteien und begründet keine Verantwortung für die Plattform.</li>
        <li>Die Plattform haftet nicht für materielle, moralische, finanzielle oder andere Schäden, die sich aus Streitigkeiten, Missverständnissen, Vertragsverletzungen oder sonstigen Problemen ergeben, die aus der Beziehung zwischen Schüler und Lehrer entstehen.</li>
    </TermUl>

    <TermSubtitle>3. Verantwortung des Nutzers (Schüler)</TermSubtitle>
    <TermUl>
        <li>Der Schüler verpflichtet sich, alle notwendigen Überprüfungen hinsichtlich der Glaubwürdigkeit des Lehrers durchzuführen, wie z. B. Interviews oder das Einholen von Nachweisen über Qualifikationen oder Referenzen, bevor er eine Vereinbarung formalisiert.</li>
        <li>Der Schüler erkennt an, dass die Nutzung der Plattform auf eigenes Risiko erfolgt, und entlastet die Plattform von jeglicher Haftung für Handlungen, Unterlassungen oder Verhaltensweisen der registrierten Lehrer.</li>
    </TermUl>

    <TermSubtitle>4. Akzeptanz der Bedingungen</TermSubtitle>
    <Text>Durch die Nutzung der Plattform erklärt der Schüler ausdrücklich, dass:</Text>
    <TermUl>
        <li>Er versteht, dass die Plattform nur als Vermittlungsraum dient und an keiner Verhandlung oder Vereinbarung zwischen dem Schüler und dem Lehrer beteiligt ist;</li>
        <li>Er versteht, dass es in seiner Verantwortung liegt, die Bedingungen der Vereinbarung zu prüfen und alle damit verbundenen Risiken zu übernehmen;</li>
        <li>Er die Plattform von jeglicher Haftung für Probleme oder Schäden entlastet, die sich aus der Beziehung mit dem Lehrer ergeben.</li>
      </TermUl>
      <SignatureSection>
        <Text><strong>Erklärung des Nutzers (Schüler)</strong></Text>
        <Check success onChange={() => setChecked(!checked)}
            checked={checked}
            label={
              <Text>Ich erkläre, dass ich die oben genannten Bedingungen gelesen, verstanden und vollständig akzeptiert habe. Ich erkenne an, dass die Plattform keine Verantwortung für geschäftliche Vereinbarungen oder Probleme übernimmt, die zwischen mir und dem Lehrer auftreten können, und übernehme alle Risiken und Verantwortlichkeiten im Zusammenhang mit der Beauftragung der registrierten Lehrer.</Text>
            }
        />
      </SignatureSection>
      </TermContainer>
  )
}