import styled from 'styled-components';

export const Text = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-family: 'Poppins';
  cursor: pointer;
`

export const OptionsContainer = styled.div.attrs({
})`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Option = styled.div.attrs({
})`
  cursor: pointer;
  font-weight: ${props => props.bold ? "700" : "500"};
  font-family: 'Poppins';
  padding-left: 10px;
`