import Check from "components/Form/Check";
import { TermContainer, TermSubtitle, Text, SignatureSection, TermUl } from "./styled";

export default function French({checked, setChecked}) {
  return (
    <TermContainer>
    {/* <TermTitle>Termo de Uso e Isenção de Responsabilidade</TermTitle> */}
    <Text><strong>Plateforme de Mise en Relation avec des Professeurs Particuliers</strong></Text>
    <Text>Ce document établit les Conditions d'Utilisation et la Décharge de Responsabilité applicables à l'utilisation de la plateforme de référencement et de mise en relation avec des professeurs particuliers, ci-après dénommée <strong>"Plateforme"</strong>, par des étudiants souhaitant engager des cours particuliers. En utilisant la Plateforme et en contactant les professeurs inscrits, l'utilisateur (étudiant) déclare avoir lu, compris et accepté les termes énoncés ci-dessous.</Text>

    <TermSubtitle>1. Objet de la Plateforme</TermSubtitle>
    <Text>La Plateforme est exclusivement un outil de référencement, fonctionnant comme une galerie de profils de professeurs particuliers fournissant leurs coordonnées et leurs services. La Plateforme n'effectue aucune médiation, gestion ou contrôle des négociations, accords ou prestations entre étudiants et professeurs.</Text>

    <TermSubtitle>2. Décharge de Responsabilité</TermSubtitle>
    <TermUl>
        <li>La Plateforme n'est pas responsable de l'exactitude des informations fournies par les professeurs inscrits, y compris, mais sans s'y limiter, leurs qualifications, expériences, certifications ou compétences techniques.</li>
        <li>L'étudiant est seul responsable de vérifier l'identité, la crédibilité et les qualifications du professeur avant de conclure tout accord ou paiement.</li>
        <li>Toute négociation, tout accord, tout paiement ou toute relation établie entre l'étudiant et le professeur se fait aux risques exclusifs des parties impliquées, sans engager la responsabilité de la Plateforme.</li>
        <li>La Plateforme n'est pas responsable des dommages matériels, moraux, financiers ou de toute autre nature résultant de litiges, malentendus, manquements ou tout autre problème découlant de la relation entre étudiant et professeur.</li>
    </TermUl>

    <TermSubtitle>3. Responsabilité de l'Utilisateur (Étudiant)</TermSubtitle>
    <TermUl>
        <li>L'étudiant s'engage à effectuer toutes les vérifications nécessaires concernant la crédibilité du professeur, telles que des entretiens ou la demande de preuves de qualifications ou de références, avant de formaliser tout accord.</li>
        <li>L'étudiant reconnaît que l'utilisation de la Plateforme se fait à ses propres risques, déchargeant la Plateforme de toute responsabilité pour les actes, omissions ou comportements des professeurs inscrits.</li>
    </TermUl>

    <TermSubtitle>4. Acceptation des Conditions</TermSubtitle>
    <Text>En utilisant la Plateforme, l'étudiant déclare expressément que :</Text>
    <TermUl>
        <li>Il comprend que la Plateforme agit uniquement comme un espace de référencement et ne participe à aucune négociation ou accord conclu entre l'étudiant et le professeur ;</li>
        <li>Il comprend qu'il est de sa responsabilité de vérifier les conditions de l'engagement et d'assumer tous les risques associés ;</li>
        <li>Il décharge la Plateforme de toute responsabilité pour tout problème ou dommage découlant de la relation avec le professeur.</li>
      </TermUl>
      <SignatureSection>
        <Text><strong>Déclaration de l'Étudiant</strong></Text>
        <Check success onChange={() => setChecked(!checked)}
            checked={checked}
            label={
              <Text>Je déclare avoir lu, compris et accepté pleinement les conditions énoncées ci-dessus. Je reconnais que la Plateforme n'a aucune responsabilité concernant les accords commerciaux ou les problèmes qui pourraient survenir entre moi et le professeur, assumant tous les risques et responsabilités associés à l'engagement des professeurs inscrits.</Text>
            }
        />
      </SignatureSection>
      </TermContainer>
  )
}