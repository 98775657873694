export const static_translation = {
    "bar_chart_general": "Allgemein",
    "bar_chart_students": "Ihre Schüler",
    "dashboard_audio_player_ditado": "Pausiert",
    "dashboard_audio_player_original": "Original",
    "dashboard_become_teacher": "Lehrerprofil aktivieren",
    "dashboard_create_new_password_check1_text": "Großbuchstabe",
    "dashboard_create_new_password_check2_text": "Zahl",
    "dashboard_create_new_password_check3_text": "Mindestens 6 zeichen",
    "dashboard_create_new_password_check4_text": "Satzzeichen",
    "dashboard_create_new_password_step1_text": "Geben Sie ein Passwort ein",
    "dashboard_create_new_password_step2_text": "Passwort wiederholen",
    "dashboard_createpassword_step1_text": "Geben Sie ein neues Passwort für Ihr Konto ein",
    "dashboard_createpassword_step1_title": "Neues Passwort",
    "dashboard_createpassword_step2_text": "Sie können sich jetzt mit Ihrem neuen Passwort anmelden",
    "dashboard_createpassword_step2_title": "Neues Passwort erfolgreich erstellt",
    "dashboard_dict_added_increase": "Sie haben mehr hinzugefügt",
    "dashboard_dict_added_words": "wörter zu deinem Vokabular.",
    "dashboard_dict_added_words_singular": "wort zu Ihrem Wortschatz.",
    "dashboard_dict_back_homepage": "Zur Startseite zurückkehren",
    "dashboard_dict_congrats": "Herzlichen Glückwunsch!",
    "dashboard_dict_obs": "Beobachtungen",
    "dashboard_dict_restart": "Neues Diktat starten",
    "dashboard_dict_see_obs": "Beobachtungen anzeigen",
    "dashboard_dict_see_translate": "Übersetzung anzeigen",
    "dashboard_dict_translate": "Übersetzung",
    "dashboard_dict_writing_handed": "Handschrift",
    "dashboard_dict_writing_meta": "Ziel",
    "dashboard_dict_writing_progress": "Ihr Fortschritt",
    "dashboard_dict_writing_special": "Sonderzeichen",
    "dashboard_dict_writing_step_1": "1. Laden Sie die Mobdict Image Sender App aus dem App Store oder Play Store herunter und melden Sie sich an.",
    "dashboard_dict_writing_step_1_web": "1. Scannen Sie den QR-Code daneben. Sie werden auf die Mobdict Image Sender-Website weitergeleitet.",
    "dashboard_dict_writing_step_2": "2. Schreiben Sie das Diktat, das Sie gehört haben, auf ein Papier und machen Sie ein Foto.",
    "dashboard_dict_writing_step_2_web": "2. Schreiben Sie das Diktat, das Sie gehört haben, von Hand auf und machen Sie ein Foto.",
    "dashboard_dict_writing_step_3": "3. Senden Sie das Bild über die App und klicken Sie auf die Schaltfläche daneben, um abzuschließen.",
    "dashboard_dict_writing_step_3_web": "3. Senden Sie das Bild über die Website und klicken Sie auf die Schaltfläche daneben, um abzuschließen.",
    "dashboard_dict_writing_step_action": "Bereits gesendet",
    "dashboard_dict_writing_step_confirm": "Ich habe überprüft, Korrigieren",
    "dashboard_dict_writing_title": "Geben Sie den Text ein",
    "dashboard_dict_writing_words": "wörter",
    "dashboard_dict_writing_words_singular": "wort",
    "dashboard_dict_wrong_challange": "Herausforderung",
    "dashboard_dict_wrong_finish": "Beenden",
    "dashboard_dict_wrong_omit": "ausgelassene wörter",
    "dashboard_dict_wrong_omit_singular": "ausgelassenes wort",
    "dashboard_dict_wrong_reinforcement": "Verstärkungsdiktat machen",
    "dashboard_dict_wrong_words": " wörter sind falsch",
    "dashboard_dict_wrong_words_singular": "wort ist falsch",
    "dashboard_forgot_step1_text": "Geben Sie Ihre registrierte E-Mail-Adresse ein, um Ihr Passwort wiederherzustellen",
    "dashboard_forgot_step1_title": "Passwort vergessen?",
    "dashboard_forgot_step1_title_afirm": "Ich habe mein Passwort vergessen",
    "dashboard_forgot_step2_text": "Greifen Sie auf den Wiederherstellungslink zu, der an Ihre registrierte E-Mail gesendet wurde",
    "dashboard_forgot_step2_title": "Überprüfen Sie Ihre E-Mail",
    "dashboard_form_account_title": "Mein Konto",
    "dashboard_form_back": "Zurück",
    "dashboard_form_balance_title": "Kreditkontrolle",
    "dashboard_form_cancel": "Abbrechen",
    "dashboard_form_confirm_password": "Passwort wiederholen",
    "dashboard_form_delete_checkbox": "Kontolöschung",
    "dashboard_form_delete_confirmar": "Bestätigen",
    "dashboard_form_delete_msg": "Nach der Bestätigung wird Ihre Anfrage an unser Team weitergeleitet. Die Löschung des Kontos und Ihrer Daten wird innerhalb von 72 Stunden endgültig und unwiderruflich abgeschlossen. Sobald der Prozess abgeschlossen ist, erhalten Sie eine Bestätigungs-E-Mail. Mit einem Klick auf 'Weiter' stimmen Sie diesen Bedingungen zu.",
    "dashboard_form_email": "Email",
    "dashboard_form_level": "Niveau",
    "dashboard_form_name": "Vollständiger Name",
    "dashboard_form_next": "Fortsetzen",
    "dashboard_form_nickname": "Wählen Sie Ihren Benutzernamen (Spitzname)",
    "dashboard_form_password": "Passwort",
    "dashboard_form_password_title": "Passwort ändern",
    "dashboard_form_password_validation": "Neues Passwort und Passwortbestätigung stimmen nicht überein",
    "dashboard_form_phone": "Mobiltelefon",
    "dashboard_form_save": "Speichern",
    "dashboard_form_subgender": "Wählen Sie die literarischen Untergenres",
    "dashboard_form_teacher": "Lehrercode",
    "dashboard_home_cta": "Lassen Sie uns mit dem Diktat beginnen",
    "dashboard_home_dicts_days": "verbleibende Tage",
    "dashboard_home_dicts_dict": "Diktate",
    "dashboard_home_dicts_done": "abgeschlossen",
    "dashboard_home_dicts_plan": "Vertragte Diktate",
    "dashboard_home_dicts_test": "Testzeitraum",
    "dashboard_home_dicts_test_done": "Testzeitraum beendet",
    "dashboard_home_learning": "Ich lerne",
    "dashboard_home_level": "Niveau",
    "dashboard_home_ranking_name": "Name",
    "dashboard_home_ranking_points": "Punktzahl",
    "dashboard_home_ranking_position": "Platzierung",
    "dashboard_home_ranking_see_more": "Mehr anzeigen",
    "dashboard_home_ranking_title": "Rangliste",
    "dashboard_home_see_plans": "Pläne anzeigen",
    "dashboard_home_teacher": "Professor",
    "dashboard_no_plans": "Pläne bald verfügbar.",
    "dashboard_password_title": "Erstellen Sie Ihr Passwort",
    "dashboard_plan_action": "Abonnieren",
    "dashboard_plan_benefits": "Vorteile anzeigen",
    "dashboard_plan_currency": "US$",
    "dashboard_plan_dict": "diktate",
    "dashboard_plan_free_action": "Testen",
    "dashboard_plan_name": "Kostenlos",
    "dashboard_plan_pop": "Am beliebtesten",
    "dashboard_plan_subtitle": "jährlich berechnet",
    "dashboard_plan_title": "Abonnieren Sie einen unserer Pläne",
    "dashboard_qtdade_TD": "Testphase",
    "dashboard_ranking_empty": "Keine Informationen gefunden",
    "dashboard_register_and": "und",
    "dashboard_register_consent_term_1": "Ich genehmige die Weitergabe der ausgewählten Elemente an andere Plattformbenutzer.",
    "dashboard_register_gallery_term_1": "Ich möchte in der Galerie der Sprachlehrer erscheinen.",
    "dashboard_register_level": "Sprache und Niveau auswählen",
    "dashboard_register_step0_student": "Schüler",
    "dashboard_register_step0_teacher": "Lehrer",
    "dashboard_register_step0_text": "Geben Sie an, in welcher Kategorie Sie sich registrieren möchten",
    "dashboard_register_step0_title": "Definieren Sie Ihr Profil",
    "dashboard_register_step1_action": "Fortsetzen",
    "dashboard_register_step1_text": "Geben Sie Ihre Registrierungsdaten an",
    "dashboard_register_step1_title": "Geben Sie Ihre Daten ein",
    "dashboard_register_step2_action": "Schließen",
    "dashboard_register_step2_back": "Zurück",
    "dashboard_register_step2_text": "Danke für Ihre Anmeldung.",
    "dashboard_register_step2_title": "Registrierung erfolgreich abgeschlossen",
    "dashboard_register_subgender": "Wählen Sie die literarischen Untergenres",
    "dashboard_register_subtitle": "Geben Sie Ihre Registrierungsdaten ein",
    "dashboard_register_successfull": "Konto erfolgreich erstellt",
    "dashboard_register_teacher_code": "Identifizieren Sie Ihren Lehrer",
    "dashboard_register_teacher_step1_text": "Geben Sie Ihre sozialen Netzwerke und ein Profilbild an",
    "dashboard_register_teacher_step1_title": "Soziale Netzwerke",
    "dashboard_register_terms_1": "Durch Auswahl und Fortfahren stimmen Sie unseren zu",
    "dashboard_register_terms_2": "Nutzungsbedingungen",
    "dashboard_register_terms_3": "Datenschutzrichtlinie",
    "dashboard_register_title": "Geben Sie Ihre Daten ein",
    "dashboard_register_validation_accept_terms": "Akzeptieren Sie die Bedingungen",
    "dashboard_register_validation_email": "Geben Sie die E-Mail ein",
    "dashboard_register_validation_not_match": "Neues Passwort und Bestätigung stimmen nicht überein",
    "dashboard_register_validation_password": "Geben Sie das neue Passwort ein",
    "dashboard_register_validation_password_confirmation": "Geben Sie die Bestätigung des neuen Passworts ein",
    "dashboard_send_dict_all": "Alle",
    "dashboard_send_dict_students_clean": "Löschen",
    "dashboard_send_dict_students_subtitle": "Wählen Sie aus, welchen Schülern Sie dieses Diktat senden möchten",
    "dashboard_send_dict_students_title": "Schüler auswählen",
    "dashboard_send_dict_title": "Schreiben Sie Ihr Diktat",
    "dashboard_settings_2fa": "Zwei-Faktor-Authentifizierung aktivieren",
    "dashboard_settings_active": "Aktiv in der Galerie",
    "dashboard_settings_adjust_pause": "Diktatpause anpassen",
    "dashboard_settings_consent": "Berechtigungen zurücksetzen",
    "dashboard_settings_consent_title": "Wählen Sie die Berechtigungen aus.",
    "dashboard_settings_default_lang": "Standardsprache",
    "dashboard_settings_define": "Festlegen",
    "dashboard_settings_languages": "Unterrichtssprachen zurücksetzen",
    "dashboard_settings_learn_lang": "Lernsprache",
    "dashboard_settings_native_lang": "Muttersprache",
    "dashboard_settings_pause": "Pause",
    "dashboard_settings_recurrency": "Wiederkehrendes Abonnement",
    "dashboard_settings_subgender": "Literarische Untergenres zurücksetzen",
    "dashboard_settings_title": "Einstellungen",
    "dashboard_side_account": "Mein Konto",
    "dashboard_side_balance": "Bilanz",
    "dashboard_side_buy": "Diktate Hinzufügen",
    "dashboard_side_dict": "Diktat veröffentlichen",
    "dashboard_side_exit": "Abmelden",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_home": "Startseite",
    "dashboard_side_ranking": "Rangliste",
    "dashboard_side_settings": "Einstellungen",
    "dashboard_side_students": "Meine Schüler",
    "dashboard_side_talk_us": "Kontaktieren Sie uns",
    "dashboard_side_teachers": "Lehrergalerie",
    "dashboard_side_tutorial": "Tutorials",
    "dashboard_subtitle_subgender": "Mindestens 3",
    "dashboard_table_balance_1": "Vertragte Diktate",
    "dashboard_table_balance_2": "Generierte Diktate Nicht Durchgeführt",
    "dashboard_table_balance_3": "Abgeschlossene Diktate",
    "dashboard_table_balance_4": "Verfügbare Diktate",
    "dashboard_table_balance_desc": "Beschreibung",
    "dashboard_table_balance_qtd": "Menge",
    "dashboard_talkus_about_text": "Wir sind leidenschaftlich in Bezug auf Sprachen und Bildung. Unser Ziel ist es, das Lernen für alle zugänglich und unterhaltsam zu machen, indem wir einen innovativen Ansatz basierend auf Sprichwörtern nutzen. Bei Mobdict® wird Ihr Sprachenlernen mit Sprichwörtern einfacher und unterhaltsamer! Diese kleinen Weisheitsperlen bieten eine einzigartige Möglichkeit, Kultur und Sprache aufzusaugen. Beim Erlernen einer neuen Sprache entdecken Sie Sprichwörter, die die Denk- und Lebensweise der Muttersprachler widerspiegeln. Jede Redewendung bringt eine wertvolle Lektion und kulturellen Kontext, die Ihnen helfen, Nuancen zu meistern und natürlicher zu klingen.",
    "dashboard_talkus_about_title": "Über uns",
    "dashboard_talkus_email": "Email",
    "dashboard_talkus_support_address": "Adresse",
    "dashboard_talkus_support_email": "Technischer Support",
    "dashboard_talkus_support_social": "Soziale Medien",
    "dashboard_talkus_title": "Kontaktieren Sie uns",
    "dashboard_teacher_above_avg": "über dem allgemeinen Durchschnitt",
    "dashboard_teacher_average": "im Durchschnitt",
    "dashboard_teacher_below_avg": "unter dem allgemeinen Durchschnitt",
    "dashboard_teacher_dicts_title": "Diktate von Schülern durchgeführt",
    "dashboard_teacher_show_more": "Mehr sehen",
    "dashboard_teacher_words_title": "Von Schülern assimilierte Wörter",
    "dashboard_teacher_wrong_subtitle": "Vorkommnisse",
    "dashboard_teacher_wrong_title": "Am häufigsten falsch geschriebene Wörter - Ihre Schüler",
    "dashboard_teacher_wrong_title_general": "Am häufigsten falsch geschriebene Wörter - Allgemein",
    "dict_a_newdict": "Neues Diktat starten",
    "dict_by": "Diktiert von: ",
    "dict_correct": "Korrigieren",
    "dict_finish": "Beenden",
    "dict_form_dict": "Diktat",
    "dict_form_dict_error": "Text eingeben.",
    "dict_form_obs": "Beobachtungen",
    "dict_form_ref": "Referenz (geben Sie 'N/A' ein, falls nicht vorhanden)",
    "dict_form_reference_error": "Referenz eingeben.",
    "dict_form_select_error": "Wählen Sie mindestens einen Schüler aus.",
    "dict_next": "Fortsetzen",
    "dict_reference": "Referenz:",
    "dict_reinforcement": "Verstärkungsdiktat",
    "dict_word_bellow": "Verwenden Sie die unten stehenden Wörter, um den Text zu vervollständigen",
    "faq_title": "FAQ - Häufig gestellte Fragen",
    "footer_all_rights": "Alle Rechte vorbehalten",
    "footer_support": "Unterstützung",
    "footer_talk_us": "Kontaktieren Sie uns",
    "gallery_consent_modal_title": "Nutzungsbedingungen und Haftungsausschluss",
    "gallery_info_title": "Kontaktinformationen",
    "gallery_languages": "Sprachen",
    "gallery_score": "Punktzahl",
    "galley_student_count": "Schüler in Mobdict",
    "galley_students_count": "Schüler auf Mobdict",
    "helpicon_languages": "Markieren Sie die Sprachen, die Sie unterrichten",
    "helpicon_level": "Sprachniveaus im Sprachenlernen gemäß dem Gemeinsamen Europäischen Referenzrahmen für Sprachen (GER)",
    "helpicon_native_lang": "Die Übersetzungen werden in dieser Sprache sein.",
    "helpicon_score": "Die Punktzahl repräsentiert die durchschnittliche Anzahl der von diesem Lehrer pro Schüler assimilierten Wörter",
    "helpicon_subgender": "Die Sprichwörter werden basierend auf diesen Auswahlmöglichkeiten erstellt, was sie interessanter und besser auf Ihre Vorlieben abgestimmt macht.",
    "helpicon_teacher": "Wenn Sie bereits einen Lehrer haben, geben Sie den bereitgestellten Code ein oder fordern Sie ihn direkt an.",
    "helpicon_username": "Diese Information wird für andere Mobdict-Nutzer sichtbar sein.",
    "Infantojuvenil": "Kinder und Jugend",
    "landpage_banner_balloon": "Verbessern Sie eine neue Sprache!",
    "landpage_download_button": "Download verfügbar auf",
    "landpage_flags_title": "Verbessern Sie eine Sprache mit dem Mobdict®",
    "landpage_free_action": "Ich möchte es ausprobieren",
    "landpage_free_text": "Sie können die Sprache üben, die Sie gerade lernen, auf Ihrem aktuellen Niveau, indem Sie Mobdict® eine Woche lang kostenlos testen und bis zu 5 Diktate machen, um Ihren Wortschatz mit jeder Herausforderung zu erweitern.",
    "landpage_free_title": "Machen Sie einen kostenlosen Test",
    "landpage_how_works_action": "Ich möchte die Pläne kennen",
    "landpage_how_works_text": "Wir sind leidenschaftlich an Sprachen und Bildung interessiert. Unser Ziel ist es, das Lernen für alle zugänglich und unterhaltsam zu machen, indem wir einen innovativen Ansatz basierend auf Diktaten verwenden. Wir sind leidenschaftlich an Sprachen und Bildung interessiert. Unser Ziel ist es, das Lernen für alle zugänglich und unterhaltsam zu machen, indem wir einen innovativen Ansatz basierend auf Diktaten verwenden.",
    "landpage_how_works_title": "Unsere größte Zufriedenheit ist es, beim Erlernen einer neuen Sprache zu helfen",
    "landpage_learn_action": "Jetzt anfangen",
    "landpage_learn_text": "Unsere Lehrmethode konzentriert sich darauf, Sprachen durch Diktate zu üben, eine Technik, die Hörverständnis, Lesen und Schreiben kombiniert, um Ihre Sprachfähigkeiten auf natürliche und effektive Weise zu verbessern.",
    "landpage_learn_title": "Lernen Sie viel mehr 😎",
    "landpage_more_text": "Schauen Sie sich unser Video an, um zu verstehen, wie das Erlernen von Sprachen durch Diktate Ihnen zugutekommen kann.",
    "landpage_more_title": "Lernen Sie auf die beste Art und Weise 🎬",
    "landpage_write_text": "Handschriftliches Schreiben kann die Beibehaltung und das Verständnis des Vokabulars verbessern, da es tiefere kognitive Prozesse erfordert. Senden Sie Ihre Handschriften an Mobdict® mit der Mobdict Image Sender App.",
    "landpage_write_title": "Handschriftlich schreiben und mehr mit Mobdict® lernen",
    "lg-acc": "Registrieren",
    "lg-acc-txt": "Haben Sie noch kein Konto? <a id=\"lg-acc\" href=\"/apl/register\">Registrieren</a>",
    "lg-auth-text": "Mit der <strong>Google Authenticator</strong> App oder einer anderen Authentifizierungs-App scannen Sie den unten stehenden QRCode, um Ihr Token zu generieren\r\n",
    "lg-email": "Email",
    "lg-error-pc": "Füllen Sie alle Felder aus",
    "lg-error-pc-cloudflare": "Warten Sie auf die Überprüfung oder aktivieren Sie das Cloudflare-Kontrollkästchen.",
    "lg-error-pc-token": "Füllen Sie das Feld aus",
    "lg-fgp": "Passwort vergessen?",
    "lg-fgpx": "Haben Sie Ihr Passwort vergessen?",
    "lg-google-auth": "Geben Sie den Code in der Authentifizierungs-App ein",
    "lg-idioma": "Sprache",
    "lg-legend-login": "Anmeldung",
    "lg-legend-token": "Geben Sie Ihren Token ein",
    "lg-senha": "Passwort",
    "lg-token": "Token",
    "login": "Einloggen",
    "login_mfa": "Verwenden Sie die Authentifizierungs-App Ihrer Wahl und scannen Sie den untenstehenden QR-Code, um Ihr Token zu generieren",
    "login_mfa_qrcode": "Neuen QR-Code generieren",
    "login-2": "Authentifizierung...",
    "login-alt": "Eintreten mit",
    "login-signup": "Sie haben kein Konto?",
    "notifications_modal_all_read": "Alles als gelesen markieren",
    "notifications_modal_any": "Sie haben keine Benachrichtigungen.",
    "notifications_modal_button_1": "Alle",
    "notifications_modal_button_2": "Ungelesen",
    "notifications_modal_message": "Neues Diktat verfügbar!",
    "notifications_modal_time_more": "Vor ** Tagen",
    "notifications_modal_time_one": "Vor 1 Tag",
    "notifications_modal_time_today": "Heute",
    "notifications_modal_title": "Benachrichtigungen",
    "payment_promo_code": "Aktionscode",
    "payments_copy_paste": "Pix Copia e Cola",
    "payments_copy_paste_tooltip": "Copiado",
    "pix_error": "Erro ao gerar Qr Code Pix",
    "pix_success": "Cobrança Pix criada com sucesso",
    "plan_teacher_benefits_1": "Praktische Erfahrung – Machen Sie bis zu 5 kostenlose Diktate, um das Tool aus der Perspektive Ihrer Schüler zu erkunden.",
    "plan_teacher_benefits_2": "Fortschrittsverfolgung – Nach der Validierung Ihres Profils als Lehrer verfolgen Sie die Entwicklung Ihrer abonnierten Schüler in Echtzeit.",
    "plan_teacher_benefits_3": "Sichtbarkeit und Möglichkeiten – Bewerben Sie Ihre Dienstleistungen und sozialen Netzwerke direkt auf der Plattform, um sich mit Schülern zu verbinden, die nach Kursen oder Privatunterricht suchen.",
    "plan_teacher_benefits_cta": "Treten Sie Mobdict bei und erweitern Sie Ihre Möglichkeiten als Pädagoge!",
    "plan_teacher_benefits_text": "Wenn Sie sich auf der Plattform registrieren, haben Sie Zugriff auf exklusive Funktionen, um Ihre Erfahrung zu optimieren und das Lernen Ihrer Schüler zu verbessern.",
    "plan_teacher_benefits_title": "Vorteile für Lehrer auf Mobdict",
    "plans_form_payments": "Zahlungsmethode",
    "plans_payment_card": "Kreditkarte",
    "register_validation_language": "Wählen Sie mindestens eine Sprache aus.",
    "register_validation_subgender": "Wählen Sie mindestens 3 Untergenres aus, um fortzufahren",
    "search_label_teachers": "Lehrer suchen",
    "search_placeholder": "Suchen...",
    "settings_saved": "Änderungen vorgenommen. Klicken Sie auf 'Weiter', um sie zu speichern.",
    "settings_teacher_warning": "Der ausgewählte Lehrer unterrichtet diese Sprache nicht. Bitte geben Sie einen anderen Code ein (lassen Sie das Feld leer, wenn Sie ohne Lehrer fortfahren möchten).",
    "settings_teacher_warning_error": "Lehrer oder Sprache nicht geändert.",
    "settings_teacher_warning_title": "Ändern Sie Ihren Lehrer",
    "students_dict": "Durchgeführte Diktate",
    "students_known": "Wörter assimiliert:",
    "students_label_green": "Assimiliertes Wort",
    "students_label_red": "Nicht assimiliertes Wort",
    "students_languages": "Lernsprachen:",
    "students_title": "Meine Schüler",
    "students_wrong": "Falscheste Wörter:",
    "teacher_cv_title": "Geben Sie einen kurzen Lebenslauf an",
    "teacher_form_city": "Stadt",
    "teacher_form_country": "Land",
    "teacher_form_cv": "Lebenslauf",
    "teacher_form_facebook": "Facebook",
    "teacher_form_instagram": "Instagram",
    "teacher_form_language": "Unterrichtete Sprachen",
    "teacher_form_linkedin": "LinkedIn",
    "teacher_form_photo": "Foto",
    "teacher_form_social": "Soziale Medien",
    "teacher_form_state": "Föderale Einheit",
    "teacher_location_title": "Staatsangehörigkeit",
    "teacher_pix_checkout_error": "Sie können kein Abonnement als Lehrer abschließen.",
    "teacher_register_consent_error": "Wählen Sie mindestens eine Kontaktmethode aus (E-Mail oder Telefon)",
    "teacher_register_consent_location": "Staatsangehörigkeit",
    "teacher_register_consent_term_error": "Akzeptieren Sie den Begriff",
    "teacher_register_info_1": "Ihre Profilinformationen sind für alle Benutzer sichtbar. Wählen Sie aus, welche Sie teilen möchten:",
    "teacher_register_info_2": "ACHTUNG: Nach der Aktivierung Ihres Profils als Lehrer können Sie keine Diktate mehr auf der Plattform durchführen, aber Sie können die Leistung Ihrer Schüler verfolgen.",
    "teacher_register_language_error": "Geben Sie mindestens eine Sprache ein",
    "teacher_register_languages": "Sprachen",
    "teacher_register_languages_subtitle": "Markieren Sie die Sprachen, die Sie unterrichten",
    "teacher_register_photo_error": "Fehler beim Speichern des Bildes",
    "teacher_register_photo_type_error": "Ungültiges Format. Verwenden Sie png, jpeg oder jpg",
    "teacher_students_subtitle": "Legende",
    "teachers_title": "Galerie der Lehrer",
    "title": "Willkommen",
    "val-token": "Validieren",
    "val-token-2": "Validierung...",
    "x26": "Durch Auswahl und Fortfahren stimmen Sie unseren Nutzungsbedingungen, Datenschutzrichtlinien und LGPD zu",
    "x9": "Durch Auswahl und Fortfahren stimmen Sie unseren Nutzungsbedingungen und unserer Datenschutzrichtlinie zu"
}