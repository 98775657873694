import Check from "components/Form/Check";
import { TermContainer, TermSubtitle, Text, SignatureSection, TermUl } from "./styled";

export default function Hindi({checked, setChecked}) {
  return (
    <TermContainer>
    {/* <TermTitle>Termo de Uso e Isenção de Responsabilidade</TermTitle> */}
    <Text><strong>निजी शिक्षकों के लिए प्लेटफ़ॉर्म</strong></Text>
    <Text>यह दस्तावेज़ निजी शिक्षकों के पंजीकरण और प्रोफाइल प्रदर्शित करने के लिए प्लेटफ़ॉर्म (आगे "प्लेटफ़ॉर्म" कहा गया है) के उपयोग के लिए उपयोग की शर्तें और जिम्मेदारी से छूट स्थापित करता है। प्लेटफ़ॉर्म का उपयोग करके और पंजीकृत शिक्षकों से संपर्क करके, उपयोगकर्ता (छात्र) यह घोषणा करता है कि उसने यहां दिए गए शर्तों को पढ़ा, समझा और स्वीकार किया है।</Text>

    <TermSubtitle>1. प्लेटफ़ॉर्म का उद्देश्य</TermSubtitle>
    <Text>प्लेटफ़ॉर्म केवल एक प्रोफाइल प्रदर्शित करने का उपकरण है, जो निजी शिक्षकों के प्रोफाइल को उनकी संपर्क जानकारी और सेवाओं के साथ प्रदर्शित करता है। प्लेटफ़ॉर्म छात्रों और शिक्षकों के बीच वार्ता, अनुबंध या सेवा निष्पादन का कोई प्रबंधन, मध्यस्थता, या नियंत्रण नहीं करता है।</Text>

    <TermSubtitle>2. जिम्मेदारी से छूट</TermSubtitle>
    <TermUl>
        <li>प्लेटफ़ॉर्म पंजीकृत शिक्षकों द्वारा दी गई जानकारी की सटीकता, जैसे उनकी योग्यता, अनुभव, प्रमाणपत्र, या तकनीकी कौशल, की जिम्मेदारी नहीं लेता है।</li>
        <li>छात्र किसी भी अनुबंध या भुगतान से पहले शिक्षक की पहचान, विश्वसनीयता, और योग्यता की पुष्टि करने के लिए पूरी तरह जिम्मेदार है।</li>
        <li>छात्र और शिक्षक के बीच होने वाली किसी भी बातचीत, अनुबंध, भुगतान, या संबंध पूरी तरह से उनकी जिम्मेदारी और जोखिम पर होता है। प्लेटफ़ॉर्म इसके लिए उत्तरदायी नहीं है।</li>
        <li>प्लेटफ़ॉर्म किसी भी भौतिक, नैतिक, वित्तीय या अन्य नुकसान के लिए जिम्मेदार नहीं है, जो छात्र और शिक्षक के संबंध से उत्पन्न विवाद, गलतफहमियों, या अन्य समस्याओं के कारण हो सकता है।</li>
    </TermUl>

    <TermSubtitle>3. उपयोगकर्ता (छात्र) की जिम्मेदारी</TermSubtitle>
    <TermUl>
        <li>छात्र शिक्षक की विश्वसनीयता की पुष्टि करने के लिए, जैसे साक्षात्कार करना या प्रमाणपत्र/संदर्भ प्राप्त करना, सभी आवश्यक जांच करने के लिए प्रतिबद्ध है।</li>
        <li>छात्र यह स्वीकार करता है कि प्लेटफ़ॉर्म का उपयोग उसके अपने जोखिम पर है और वह पंजीकृत शिक्षकों के कार्यों, चूक, या व्यवहार के लिए प्लेटफ़ॉर्म को उत्तरदायी नहीं ठहराएगा।</li>
    </TermUl>

    <TermSubtitle>4. शर्तों की स्वीकृति</TermSubtitle>
    <Text>प्लेटफ़ॉर्म का उपयोग करके, छात्र स्पष्ट रूप से यह घोषणा करता है कि:</Text>
    <TermUl>
        <li>वह समझता है कि प्लेटफ़ॉर्म केवल एक प्रदर्शनी स्थान के रूप में कार्य करता है और छात्र और शिक्षक के बीच किसी भी बातचीत या अनुबंध में शामिल नहीं होता है;</li>
        <li>वह समझता है कि यह उसकी जिम्मेदारी है कि वह अनुबंध की पुष्टि करे और उससे जुड़े सभी जोखिम उठाए;</li>
        <li>वह प्लेटफ़ॉर्म को शिक्षक के साथ संबंध से उत्पन्न किसी भी समस्या या नुकसान से मुक्त करता है।</li>
      </TermUl>
      <SignatureSection>
        <Text><strong>Dछात्र की घोषणा</strong></Text>
        <Check success onChange={() => setChecked(!checked)}
            checked={checked}
            label={
              <Text>मैं यह घोषणा करता हूं कि मैंने ऊपर दी गई शर्तों को पढ़ा, समझा और पूरी तरह से स्वीकार किया है। मैं मानता हूं कि प्लेटफ़ॉर्म मेरे और शिक्षक के बीच किसी भी व्यावसायिक अनुबंध या समस्याओं के लिए जिम्मेदार नहीं है और पंजीकृत शिक्षकों की सेवाओं से जुड़े सभी जोखिम और जिम्मेदारियां लेता हूं।</Text>
            }
        />
      </SignatureSection>
      </TermContainer>
  )
}