import { CreateCheckout, GeneratePix, SendPhoto, ValidatePhoto } from "services/mobdict";
import { exposeMobdictError } from "utils";
import { toast } from "react-toastify";

export function highlightText({ text, words, backgroundColor, underline }) {
  // Escape HTML special characters
  const escapeHtml = (str) => str.replace(/[&<>"']/g, (match) => ({
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  }[match]));

  // Escape the text to avoid HTML injection
  text = escapeHtml(text);

  // Process each word with the specified background color
  words.forEach(word => {
    // Escape word for regex usage
    const escapedWord = escapeHtml(word);
    // Create a global regex to match the word
    const regex = new RegExp(`\\b${escapedWord}\\b`, 'gi');
    // Build the replacement HTML with dynamic styles
    let style = '';
    if (backgroundColor) {
      style += `background-color: ${escapeHtml(backgroundColor)}; `;
    }

    // Replace the text with the styled span
    text = text.replace(regex, (match) => {
      const underscores = underline ? '_'.repeat(match.length) : match;
      return `<span style="${style}">${underscores}</span>`;
    });
  });

  return text;
}

export const handleCheckout = async (data, setModal, setCheckout, navigate, loading, setLoading, payload) => {
  if (data.lp) {
    setModal(null);
    setCheckout(data.payload);
    navigate('login');
    return;
  }

  if(!loading){
    setLoading(true)

    const result = await CreateCheckout(payload)

    if( !exposeMobdictError(result, ( result?.checkout_url ) )){
        window.open(result?.checkout_url, "new")
        setModal(null);
    }

    setLoading(false)
  }
}

export const handlePixCheckout = async (data, setPixCheckout, navigate) => {
  console.log(data)
  const pix_body = {
    valor: data.valor_raw,
    idioma: data.idioma,
    chave_pesquisa: data.lookup_key,
    desconto: data.desconto,
    code: data.code
  }
  
  const response = await GeneratePix(pix_body, data.id)

  if (!exposeMobdictError(response, Object.keys(response.data).length !== 0)) {
    console.log(response.data.status)
    if (response.data.status) {
      toast.success(response.message)
      navigate('dashboard')
      return
    }
    const pix_payload = {
      ...data,
      status: "created",
      copiaECola: response.data.copiaECola,
      qrcode: response.data.qrcode
    }

    setPixCheckout(pix_payload);
  
    if (navigate === null) {
      return true;
    }
    navigate('pix-checkout')
  }
}

export const uploadPhoto = async (language, avatar, user, photo, t) => {
        try {
            const payload = {
                idioma: language,
                foto: avatar,
            }
            const photo_response = await ValidatePhoto(payload, user?.id);
            if (!exposeMobdictError(photo_response, Object.keys(photo_response.data).length !== 0)) {
                if (!photo_response.data.valido) {
                  toast.error(photo_response?.message)
                }
                const photoType = !photo_response.data.valido ? 'r' : 'a';
                const key = !photo_response.data.valido ? `${user?.id.toString()}_${Date.now()}.${photo.type.split('/')[1]}` : `${user?.id.toString()}.${photo.type.split('/')[1]}`;

                const payload = {
                    idioma: language,
                    foto: avatar,
                    photoType: photoType,
                    key: key,
                }
                const result = await SendPhoto(payload, user?.id);
                if (!exposeMobdictError(result, Object.keys(result.data).length !== 0)) {
                  if (!photo_response.data.valido) {
                      return false;
                  }
                  return true;
                } else {
                  return false;
                }
            } else {
                toast.error(t("teacher_register_photo_error"))
                return false;
            }
        } catch (error) {
            console.log(error);
            toast.error(t("teacher_register_photo_error"))
            return false;
        }
    }