import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
   Text,
   OptionsContainer,
   Option
} from "./styled";
import useI18n from 'hooks/useI18n';


export const SelectDrawer = ({ placeholder, options, value, onChange }) => {
    const [selectedValue, setSelectedValue] = useState(options[0]?.id || '');
    const [opened, setOpened] = useState(false);
    const [showLanguageList, setShowLanguageList] = useState(false);
    const [sign, setSign] = useState("+")

    const { t } = useI18n(false)

    useEffect(() => {
        if (value !== undefined) {
            setSelectedValue(value);
        }
    }, [value]);

    const toggleOpen = () => {
        setOpened(!opened);
    }

    const optionAction = selectedId => {
        setSelectedValue(selectedId);
        if (onChange && typeof onChange === 'function') {
            onChange(selectedId);
        }

        if (placeholder === 'Português') {
            try {
                window.productFruits.services.destroy()
            } catch (error) {
                console.log('error', error)
            }
        }
        toggleOpen();
    }

    return (
        <>
            <div style={{ marginBottom: '20px' }}>
            <Text
              onClick={() => {setShowLanguageList(prev => !prev); setSign(sign === "+" ? "-" : "+")}}
            >
              {t("lg-idioma")}
              <span style={{ fontSize: '18px', transform: showLanguageList ? 'rotate(180deg)' : 'rotate(0)', transition: 'transform 0.2s' }}>
                {sign}
              </span>
            </Text>

            {showLanguageList && (
              <OptionsContainer>
                {options.map(opt => (
                  <Option
                    key={opt.id}
                    onClick={() => {optionAction(opt.id)}}
                    bold={selectedValue === opt.id}
                  >
                    {opt.title}
                  </Option>
                ))}
              </OptionsContainer>
            )}
          </div>
        </>
    )
}

SelectDrawer.propTypes = {
    placeholder: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.object,
    small: PropTypes.bool,
    secondary: PropTypes.bool,
};

SelectDrawer.defaultProps = {
    placeholder: 'Cenario',
    options: [],
    value: undefined,
    small: false,
    secondary: false,
    onChange: undefined,
};

export default SelectDrawer;