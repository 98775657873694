import React, { useContext, useState } from 'react'
import { ButtonClose, ButtonContainer, CheckContainer, ModalBody, ModalContainer, ModalContent, ModalTitleContainer, Overlay } from './styled'
import { Icon, Text, Title } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'
import CheckItems from 'components/CheckItems'
import Button from 'components/Form/Button'
import { toast } from 'react-toastify'
import useI18n from 'hooks/useI18n'
import Check from 'components/Form/Check'

export default function Consent() {

  const { setModal, modal } = useContext(CoreContext)
  const [loading] = useState(false)

  const [checked, setChecked] = useState([ ...modal?.selected ])
  const [consentChecked, setConsentChecked] = useState(modal?.consentChecked)

  const { t } = useI18n(false)
 
  const save = async () => {
    if( checked.length === 0 ){
      toast.error(t("register_validation_subgender"))
      return ;
    }

    modal?.setConsentsChecked(checked)
    modal?.setConsentChecked(consentChecked)
    setModal(null)
    toast.success(t("settings_saved"))
    }

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              { t("dashboard_settings_consent_title") }
            </Title>
          </ModalTitleContainer>
          <ModalBody>
            <CheckItems options={modal?.items||[]} checked={checked} setChecked={setChecked} languages column consent/>
            <CheckContainer>
                <Check success onChange={() => setConsentChecked(!consentChecked)}
                    checked={consentChecked}
                    label={
                        <Text>
                            { t("dashboard_register_consent_term_1") }
                        </Text>
                    }
                />
            </CheckContainer>
            <ButtonContainer>
              {
                loading ? null :
                  <Button secondary width="144px" outline colorBlack loading={loading}
                    onClick={() => setModal(null)}
                  >{ t("dashboard_form_cancel") }</Button>
              }

              <Button secondary width="144px" loading={loading}
                onClick={save}
              >{ t("dashboard_form_save") }</Button>
            </ButtonContainer>
          </ModalBody>

        </ModalContent>
      </ModalContainer>
    </>
  )
}
